////////////////////////////////////////////////////////////////////////////
// Content Toggler - Finance Type
////////////////////////////////////////////////////////////////////////////

(function (window, document) {
  var defaultOption = document.querySelector('.js-default-option');
  var financeType = document.querySelector('#content-toggler__checkbox');
  var PCP = document.querySelectorAll('.isPCP');
  var HP = document.querySelectorAll('.isHP');
  var PCPElement = document.querySelectorAll('.hide--if-pcp');
  var HPElement = document.querySelectorAll('.hide--if-hp');

  // If there's an HP default option, handle the HP elements
  if (defaultOption && defaultOption.innerText === 'HP') {
    financeType.setAttribute('checked', 'checked');
    handleHPElements();
  }

  /**
   * Handle the HP elements
   */
  function handleHPElements() {
    for (let i = 0; i < PCP.length; i++) {
      PCP[i].style.display = 'none';
      HP[i].style.display = 'flex';
      for (let x = 0; x < PCPElement.length; x++) {
        PCPElement[x].classList.add('hide__default');
        HPElement[x].classList.remove('hide__default');
      }
    }
  }

  /**
   * Handle the PCP elements
   */
  function handlePCPElements() {
    for (let i = 0; i < PCP.length; i++) {
      PCP[i].style.display = 'flex';
      HP[i].style.display = 'none';
      for (let x = 0; x < PCPElement.length; x++) {
        PCPElement[x].classList.remove('hide__default');
        HPElement[x].classList.add('hide__default');
      }
    }
  }

  /**
   * On the click of the options
   */
  window.financeCheck = function financeCheck() {
    if (financeType && financeType.checked) {
      handleHPElements();
    } else {
      handlePCPElements();
    }
  };
})(window, document);
