/* eslint-disable indent */
((global) => {
  const indexName = 'prod_VEHICLES';
  const algoliaID = 'TYZID6W24Z';
  const algoliaAPI = 'deaa002b8e50738db9717c497310855e';

  const mobileView = window.matchMedia('(max-width: 640px)');

  const EXTRA_FILTERS =
    typeof global.iFilters !== 'undefined' ? global.iFilters.trim() : 'NOT Category:BIKE';
  const BRAND_REF =
    typeof global.iBrandRef !== 'undefined' && Array.isArray(global.iBrandRef)
      ? global.iBrandRef.map((brand) => `BrandRef:${brand}`)
      : [];

  const CURRENT_YEAR = new Date().getFullYear();

  const TEXT_TIME_TO_ADD = 150;
  const TEXT_TIME_TO_DELETE = 60;
  const TEXT_TIME_TO_ACTION = 500;

  // CONSTANTS REGEX
  const poundRegex = /(under|less\sthan|up\sto|from)\s(\u00A3)?([0-9]+(\s?pounds?)?)/gi;
  const milesRegex = /((under|less\sthan|up\sto|from)\s)?([0-9]+(\s?miles))/gi;
  const yearRegex = /\w+\s((20)?0\d|(20)?1[0-9])(\splate|\sreg(istration)?)?/gi;
  const ageRegex = /((under|less\sthan|up\sto|from)\s)?([0-9]|1[0-3])\syears?(\sold)?/gi;
  const underRegex = /(under|less\sthan|up\sto)/i;
  const fromRegex = /from/i;

  // Create a timer to store a timeout until it sends the request to the api
  let timer;
  let textTimer;

  const defaultOffsetText = 'Search: ';

  // Check if vrnCyclingText has been defined in VertuCentral --
  const vrnCyclingText =
    typeof global.vrnCyclingTextPlaceholder !== 'undefined'
      ? global.vrnCyclingTextPlaceholder
      : ['Your Reg'];

  // Check whether mobile or normal view and then check whether sample text is overwritten.
  const mobileViewMatch =
    typeof global.mobileTypeaheadPlaceholder !== 'undefined'
      ? global.mobileTypeaheadPlaceholder
      : ['used Ford', 'new Polo', 'blue SUV'];

  const notMobileArrayOptions = [
    'used Ford Focus from \u00A35000',
    'new vans in Glasgow',
    'vehicles under \u00A36000',
    'blue Vauxhall Corsa',
    'cars up to 1000 miles',
    'vans under 1 year old',
  ];

  const notMobileViewMatch =
    typeof global.typeaheadPlaceholder !== 'undefined'
      ? global.typeaheadPlaceholder
      : notMobileArrayOptions;

  const sampleSearch = mobileView.matches ? mobileViewMatch : notMobileViewMatch;

  // TODO: Remove this when the new menu is permanents
  const IS_NEW_NAV = localStorage.getItem('new_menu') === 'YES';

  let recognition;

  /**
   * Check if an object has a property
   * @param {Object} obj The object to check
   * @param {string} property The object property
   */
  function hasProperty(obj, property) {
    return Object.hasOwnProperty.call(obj, property);
  }

  class Store {
    /**
     * Create a store in order to save results in local storage to cache the requests
     * @param {object} options The options configs for the store
     * @param {string} [options.storeName] The store name to apply
     * @param {number} [options.validUntil] The minutes to keep the store for
     * @param {boolean} [options.autoLoad] If the store should autoload from localStorage
     */

    #storeName;

    #store;

    constructor(options = { storeName: '_typeaheadSearch_', validUntil: 15, autoLoad: true }) {
      const { storeName, validUntil, autoLoad } = options;

      this.#storeName = storeName;

      this.store = {
        // Cache all queries with the results
        queries: {},

        lastQuery: '',

        // Get the time until the storage is valid
        validUntil: new Date(new Date().getTime() + validUntil * 60 * 1000),
      };

      if (autoLoad) {
        this.load();
      }
    }

    /**
     * Getter and setter for store object
     * @param {{ queries: { [key: string]: object }, validUntil: Date, lastQuery: string }} store
     */
    set store(store) {
      this.#store = store;
    }

    get store() {
      return this.#store;
    }

    /**
     * Update the last query that was submited
     * @param {string} query The query submited
     */
    set lastQuery(query) {
      this.#store.lastQuery = query;

      this.save();
    }

    get lastQuery() {
      return this.#store.lastQuery;
    }

    /**
     * Check if the store has the provided property
     * @param {string} property The property to check
     */
    has(property) {
      return (
        Object.hasOwnProperty.call(this.store.queries, property) &&
        this.store.queries[property] !== ''
      );
    }

    /**
     * Get the property from the store
     * @param {string} property The property to get
     */
    get(property) {
      return this.store.queries[property];
    }

    /**
     * Set a property with the given value to the store
     * @param {string} property The property name
     * @param {{ content: object, query: string }} value The value to set to the store
     */
    set(property, value) {
      this.store.queries[property] = value;

      this.save();
    }

    /**
     * Remove a query from the store based on the provided property
     * @param {string} property The property name
     */
    remove(property) {
      delete this.store.queries[property];

      this.save();
    }

    /**
     * Load the store if it has not expired
     */
    load() {
      const savedStore = global.localStorage.getItem(this.#storeName);
      let savedJson;

      try {
        savedJson = JSON.parse(savedStore);
      } catch (error) {
        console.error(error);
      }

      if (savedJson && new Date(savedJson.validUntil).getTime() > new Date().getTime()) {
        this.store = savedJson;
      }
    }

    /**
     * Save the store to local storage to load later
     */
    save() {
      global.localStorage.setItem(this.#storeName, JSON.stringify(this.store));
    }
  }

  const clipPathId = '#overlayClip';
  // TODO: Remove this when the new menu is permanent
  const clipPathIdNewNav = '#overlayClipNewNav';
  const AudioContext = window.AudioContext || window.webkitAudioContext;
  class MicVolumeTracker {
    /**
     * Track the volume of the microphone and render it to the typeahead-search__mic-overlay.
     * @param {HTMLDivElement} container
     */
    constructor(container) {
      // TODO: Revisit this when the new menu is permanent
      this.overlayRect = container.querySelector(
        `${IS_NEW_NAV ? clipPathIdNewNav : clipPathId} rect`,
      );

      if (!this.overlayRect) {
        throw new Error('No typeahead-search mic overlay present...');
      }

      this.iconHeight = parseInt(getComputedStyle(container.querySelector('svg')).height, 10) || 15;

      this.volumeCallback = this.volumeCallback.bind(this);
    }

    volumeCallback() {
      const volumes = new Uint8Array(this.analyser.frequencyBinCount);
      this.analyser.getByteFrequencyData(volumes);
      const volumeSum = volumes.reduce((a, b) => a + b, 0);
      const volumeAvg = Math.round(volumeSum / volumes.length);
      if (this.overlayRect) {
        this.updateVolumeOverlay(volumeAvg);
      }
    }

    start() {
      this.stop();
      return navigator.mediaDevices
        .getUserMedia({
          audio: true,
        })
        .then((audioStream) => {
          if (!this.audioContext) {
            this.audioContext = new AudioContext();
            this.analyser = this.audioContext.createAnalyser();
            this.analyser.fftSize = 512;
            this.analyser.minDecibels = -127;
            this.analyser.smoothingTimeConstant = 0.4;
          } else {
            this.audioContext.resume();
          }
          this.audioStream = audioStream;
          const audioSource = this.audioContext.createMediaStreamSource(audioStream);
          audioSource.connect(this.analyser);

          this.volumeInterval = setInterval(this.volumeCallback, 100);
        });
    }

    stop() {
      if (this.volumeInterval) {
        clearInterval(this.volumeInterval);
        this.volumeInterval = null;
      }
      if (this.audioStream) {
        this.audioStream.getAudioTracks().forEach((track) => track.stop());
      }
    }

    /**
     * Calculate and set the height for the overlay element.
     * @param {number} value
     */
    updateVolumeOverlay(value) {
      // Normalize the value to an integer value between 0 and iconHeight
      const normalizedValue = Math.round((value * this.iconHeight) / 100);
      const overlayHeight = this.iconHeight - normalizedValue;
      this.overlayRect.style.height = overlayHeight;
    }
  }
  class SpeechToTextController {
    /**
     * Initialize the speech recognition controller
     * @param {HTMLInputElement} input The input element to add the results
     * @param {HTMLElement} button The html element that will triger the speech recognition
     * @param {Function} onResult The callback function that will be called when speech finishes
     */
    static init(input, button, onResult) {
      // Get the speech recognition class
      const SpeechRecognition = global.SpeechRecognition || global.webkitSpeechRecognition;

      // Check if input and button was passed as arguments
      if (!input || !button) {
        console.error('SpeechToTextController cannot be initialized without input and button');
        return;
      }

      // Check if web speech api exists
      if (!SpeechRecognition) {
        button.style.display = 'none';
        return;
      }

      let hasStarted = false;

      button.style.display = 'block';
      button.classList.add('typeahead-search__mic-container');

      // Create an instance of the speech recognition
      this.recognition = new SpeechRecognition();
      this.recognition.interimResults = true;

      try {
        this.micVolumeTracker = new MicVolumeTracker(button);
      } catch (e) {
        console.error(e);
        this.micVolumeTracker = null;
      }

      // Add an event listener for the results
      this.recognition.addEventListener('result', (event) => {
        // Convert the event results in an array
        const transcript = Array.from(event.results)
          .map((result) => result[0]) // Get the first result from the event results
          .map((result) => result.transcript) // Get the transcript of the result
          .join(); // Join the array to a string

        // If the user stopped speaking then call the callback if available
        if (event.results[0].isFinal) {
          // Add the transcript to the input element
          input.value = transcript;

          if (onResult && typeof onResult === 'function') {
            onResult({ event, transcript });
          }
        }
      });

      this.recognition.addEventListener('start', () => {
        button.classList.add('is-active');

        if (this.micVolumeTracker) {
          this.micVolumeTracker.start().catch((e) => {
            console.error(e);

            // TODO: Revisit this when the new menu is permanent
            const overlay = IS_NEW_NAV
              ? Array.from(button.querySelector('.typeahead-search__mic--overlay')).find(
                  (element) => element.closest('.main-nav__search'),
                )
              : button.querySelector('.typeahead-search__mic-overlay');

            if (overlay) {
              overlay.classList.add('hide__default');
            }
          });
        }
        hasStarted = true;
      });

      this.recognition.addEventListener('end', () => {
        button.classList.remove('is-active');
        hasStarted = false;
        if (this.micVolumeTracker) {
          this.micVolumeTracker.stop();
        }

        input.focus();
      });

      button.addEventListener('click', () => {
        if (!hasStarted) {
          this.recognition.start();
        } else {
          this.recognition.stop();
        }
      });
    }

    static get recognition() {
      return recognition;
    }

    static set recognition(rec) {
      recognition = rec;
    }
  }

  /**
   * Pagination algorithm from https://codereview.stackexchange.com/questions/183417/pagination-algorithm-in-js
   */
  function pagination(totalPages, range, currentPage) {
    const result = [];

    if (totalPages !== undefined && range !== undefined) {
      let start = 1;

      if (range > totalPages) {
        range = totalPages;
      }

      // Dont use negative values, force start at 1
      if (currentPage < range / 2 + 1) {
        start = 1;

        // Dont go beyond the last page
      } else if (currentPage >= totalPages - range / 2) {
        start = Math.floor(totalPages - range + 1);
      } else {
        start = currentPage - Math.floor(range / 2);
      }

      for (let i = start; i <= start + range - 1; i += 1) {
        result.push(i);
      }
    }

    return result;
  }

  /**
   * Convert a number to have commas every thousands
   * @param {string|number} num The nunber to modify
   */
  function numberWithCommas(num) {
    return parseInt(num, 10)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }

  function getPicture(pictureRef, category) {
    if (!pictureRef || pictureRef === '') {
      switch (category) {
        case 'BIKE':
          return 'https://www.bristolstreet.co.uk/custom/69600%5E780x585%5E.jpg';
        case 'COMM':
          return 'https://www.bristolstreet.co.uk/custom/57629%5E780x585%5E.jpg';
        default:
          return 'https://www.bristolstreet.co.uk/custom/52146^300x225^.jpg';
      }
    }

    return `https://www.bristolstreet.co.uk${pictureRef}`;
  }

  function getUrl(carUrl) {
    return `${carUrl}`;
  }

  /**
   * Initialize the text ticker
   * @param {HTMLInputElement} element The element to add ticked text
   * @param {string[]} textArray The text array to display
   * @param {number?} index The index to start
   * @param {string} textToShow The non-cycling text to display
   */
  function initTextTicker(element, textArray, textToShow, index = 0) {
    if (!element || !textArray.length) {
      return;
    }

    // Create delta time, old time and time passed to calculate adding/removing text
    let dt = 0;
    let oldTime;
    let timePassed = 0;

    // Booleans to handle if we are adding/removing text and if we should wait before doing so
    let shouldAdd = true;
    let shouldWait = false;

    // The minimum index to remove text
    const minIndex = textToShow.length;

    // Initialize the text variables
    let text = textArray[index];
    let textLength = text.length;
    let textIndex = 0;

    // Start the loop
    textTimer = requestAnimationFrame(function textTickerLoop(timestamp) {
      // If there was a request already done than get the delta time
      if (oldTime) {
        dt = timestamp - oldTime;
        timePassed += dt;
      }
      oldTime = timestamp;

      // Check if we should wait
      if (shouldWait) {
        if (timePassed >= TEXT_TIME_TO_ACTION) {
          shouldWait = false;
          timePassed = 0;
        }
      } else if (shouldAdd) {
        // Adding text
        if (timePassed >= TEXT_TIME_TO_ADD) {
          // If the text exists
          if (text && text !== '') {
            // If the text index is less than the length get the next character
            if (textIndex < textLength) {
              textToShow += text.charAt(textIndex);
              element.placeholder = `${textToShow}|`;
              textIndex += 1;
            } else {
              // Else stop adding and start deleting with a wait
              element.placeholder = textToShow;
              shouldAdd = false;
              textIndex += minIndex;

              shouldWait = true;
            }
          }

          // Reset the time passed
          timePassed = 0;
        }
        // Removing text
      } else if (timePassed >= TEXT_TIME_TO_DELETE) {
        // If the text index is greater than the min index to erase
        if (textIndex > minIndex) {
          // Remove the text each index
          element.placeholder = `${textToShow}|`;
          textIndex -= 1;
          textToShow = textToShow.slice(0, textIndex);
        } else {
          // Else stop removing and start adding with a wait
          element.placeholder = textToShow;
          shouldAdd = true;
          textIndex = 0;

          // Get the next text from the text array
          index = index >= textArray.length - 1 ? 0 : index + 1;
          text = textArray[index];
          textLength = text.length;

          shouldWait = true;
        }

        // Reset the time passed
        timePassed = 0;
      }

      // Loop to the next render
      textTimer = requestAnimationFrame(textTickerLoop);
    });
  }

  /**
   * The vehicle instant search main class
   * This is an abstract class
   */
  class VehicleInstantSearch {
    /**
     * Construct the vehicle search widget
     */
    constructor(alSearch, container) {
      if (new.target === VehicleInstantSearch) {
        throw new TypeError('Cannot construct Abstract instances directly.');
      }

      if (this.init === undefined) {
        throw new TypeError('`Init` method must be overrided.');
      }

      if (this.render === undefined) {
        throw new TypeError('`Render` method must be overrided.');
      }

      // Initialize the algolia client and helper
      const client = alSearch(algoliaID, algoliaAPI);
      this.index = client.initIndex(indexName);

      this.store = new Store();

      // Initialize Algolia search options
      this.defaultFilters = EXTRA_FILTERS;
      this.searchOnceOptions = {
        query: null,
        hitsPerPage: 8,
        page: 0,
        filters: this.defaultFilters,
        facetFilters: [BRAND_REF],
        facets: ['New', 'Used', 'Category', 'Year', 'Price'],
      };

      // Get the search inputs and the search hits
      this.searchInput = container.querySelector('.js-a-search-input');
      this.searchHits = container.querySelector('.js-a-search-hits');

      // Initialize the speech to text
      SpeechToTextController.init(
        this.searchInput,
        container.querySelector('.js-a-search-mic'),
        (results) => {
          this.applyQueryAndSearch(results.transcript);
        },
      );

      this.paginationPrevious = null;
      this.paginationNext = null;
      this.paginationContainer = null;
      this.numberOfPages = null;
      this.totalCountElement = null;

      // Initialize the search
      this.init();
      this.initSearch();
    }

    set queryString(query) {
      // only use safe queries
      let clean = null;
      try {
        const { DOMPurify } = window;
        clean = DOMPurify.sanitize(query);
      } catch (error) {
        // If you see this warning, the page needs the DOMPurify module
        // leaving this message somewhat vague, as outsiders dont need
        // to know what exactly is missing
        console.warn('WARNING: Missing script for form-utilities.js.');
      }
      if (window.location.href.includes('dev')) {
        this.searchOnceOptions.query = query;
      } else {
        this.searchOnceOptions.query = clean;
      }
    }

    get queryString() {
      return this.searchOnceOptions.query;
    }

    set currentPage(page) {
      this.searchOnceOptions.page = page;
    }

    get currentPage() {
      return this.searchOnceOptions.page;
    }

    initSearch() {
      const vrnInput = document.getElementById('Q57');
      this.searchInput.addEventListener('keyup', (event) => {
        if (event.key !== 'Enter') {
          this.applyQueryAndSearch(event.target.value);
        }

        event.target.placeholder = defaultOffsetText;

        if (event.target.value === '') {
          cancelAnimationFrame(textTimer);
          initTextTicker(this.searchInput, sampleSearch, 'Search: ', 0);
          initTextTicker(vrnInput, vrnCyclingText, '', 0);
        } else {
          cancelAnimationFrame(textTimer);
        }
      });

      initTextTicker(this.searchInput, sampleSearch, 'Search: ', 0);
      initTextTicker(vrnInput, vrnCyclingText, '', 0);
    }

    applyQueryAndSearch(query = '') {
      if (this.queryString !== query) {
        if (typeof this.openDropdown !== 'undefined') {
          this.openDropdown();
        }

        global.clearTimeout(timer);

        this.queryString = query;

        timer = global.setTimeout(() => {
          this.searchOnce();
        }, 250);
      }
    }

    /**
     * Search and render the display
     */
    searchOnce() {
      const options = this.handleQuerySearch(this.searchOnceOptions);

      // Create a map key based on the query string and the search options
      const mapKey = JSON.stringify(options).replace(/\s+/g, '').toLowerCase();

      if (this.searchCached(mapKey)) {
        return;
      }

      // Else make a search request
      this.index.search(options).then((content) => {
        // Render the results
        this.renderHits({ content }, this.queryString);
        // Store the results
        this.store.set(mapKey, { content, query: this.queryString });
      });
    }

    searchCached(mapKey) {
      // If the query has been fetched render the stored results
      if (this.store.has(mapKey)) {
        const { content, query } = this.store.get(mapKey);
        this.renderHits({ content }, query);
        return true;
      }

      return false;
    }

    handleQuerySearch(searchOptions) {
      let { query, filters } = searchOptions;
      let filter = '';

      if (milesRegex.test(query)) {
        const newOptions = this.handleMilesRegex(query);
        query = newOptions.query;
        filter += newOptions.filter;
      }

      if (yearRegex.test(query)) {
        const newOptions = this.handleYearRegex(query);
        query = newOptions.query;
        filter += newOptions.filter;
      }

      if (ageRegex.test(query)) {
        const newOptions = this.handleAgeRegex(query);
        query = newOptions.query;
        filter += newOptions.filter;
      }

      if (poundRegex.test(query)) {
        const newOptions = this.handlePoundRegex(query);
        query = newOptions.query;
        filter += newOptions.filter;
      }

      filters = this.defaultFilters + filter;

      return {
        ...searchOptions,
        query,
        filters,
      };
    }

    static handlePoundRegex(query) {
      const matchedText = query.match(poundRegex);
      let filter = '';

      matchedText.forEach((text) => {
        const [price] = text.match(/[0-9]+/);
        let operator;

        if (underRegex.test(text)) {
          operator = '<=';
        } else if (fromRegex.test(text)) {
          operator = '>=';
        }

        if (operator) {
          filter += ` AND Price ${operator} ${price}`;
          // Remove the matched text from the query
          query = query.replace(text, '');
        }
      });

      return { query, filter };
    }

    static handleMilesRegex(query) {
      const matchedText = query.match(milesRegex);
      let filter = '';

      matchedText.forEach((text) => {
        const [miles] = text.match(/[0-9]+/);
        let operator;

        if (underRegex.test(text)) {
          operator = '<=';
        } else if (fromRegex.test(text)) {
          operator = '>=';
        } else {
          operator = '=';
        }

        if (operator) {
          // Apply the filter
          filter += ` AND Mileage ${operator} ${miles}`;
          // Remove the matched text from the query
          query = query.replace(text, '');
        }
      });

      return { query, filter };
    }

    static handleYearRegex(query) {
      const matchedText = query.match(yearRegex);
      let filter = '';

      matchedText.forEach((text) => {
        let [year] = text.match(/[0-9]+/);
        let operator;

        if (!underRegex.test(text) && !fromRegex.test(text) && !/^to|^than/.test(text)) {
          operator = '=';
        }

        if (operator) {
          year = year.length === 4 ? year : `20${year}`;
          // Apply the filter
          filter += ` AND Year ${operator} ${year}`;
          // Remove the matched text from the query
          query = query.replace(/[0-9]+(\splate|\sreg(istration)?)?/i, '');
        }
      });

      return { query, filter };
    }

    static handleAgeRegex(query) {
      const matchedText = query.match(ageRegex);
      let filter = '';

      matchedText.forEach((text) => {
        let [age] = text.match(/[0-9]+/);
        age = parseInt(age, 10);
        let operator;

        if (/up\sto/.test(text)) {
          operator = '>=';
        } else if (/under|less\sthan/.test(text)) {
          operator = '>';
        } else if (/from/.test(text)) {
          operator = '<';
        } else {
          operator = '=';
        }

        if (operator) {
          // Apply the filter
          filter += ` AND Year ${operator} ${CURRENT_YEAR - age}`;
          // Remove the matched text from the query
          query = query.replace(text, '');
        }
      });

      return { query, filter };
    }

    /**
     * Render the hits retrieved from the Algolia API
     * @param {object} response The Algolia response
     * @param {string} query The query that was used
     */
    renderHits(response, query) {
      if (response.content.hits.length) {
        this.render(response.content.hits, response.content);
      } else {
        this.addSearchNotFound(query);
      }
    }

    clearHits() {
      this.searchHits.innerHTML = '';
    }

    /**
     * Add a message to the dropdown saying that no search were found
     * @param {string} query The query typed
     */
    addSearchNotFound(query) {
      if (this.totalCountElement) {
        this.totalCountElement.parentElement.classList.add('hide__default');
      }

      if (this.paginationContainer) {
        this.paginationContainer.parentElement.classList.add('hide__default');
      }

      const el = document.createElement('div');
      el.textContent = query;

      this.searchHits.innerHTML = `
        <div class="wrapper padding-top-15">
          <p>
            Sorry, your search
            <span class="font--bold">- ${el.textContent} -</span>
            did not match any vehicles.
          </p>
          <p>
            Suggestions:
            <ul class="list--circle">
              <li>Make sure that you have spelled all words correctly.</li>
              <li>Try different keywords.</li>
            </ul>
          </p>
        </div>
      `;
    }
  }

  class VehicleInstantSearchLanding extends VehicleInstantSearch {
    constructor(alSearch, container) {
      super(alSearch, container);

      global.goToPage = this.goToPage.bind(this);
    }

    init() {
      // Show 12 cars by default
      this.searchOnceOptions.hitsPerPage = 12;

      // Get the DOM elements
      this.totalCountElement = document.querySelector('.js-a-search-count');
      this.paginationPrevious = document.querySelector('.js-pagination-previous');
      this.paginationNext = document.querySelector('.js-pagination-next');
      this.paginationContainer = document.querySelector('.js-pagination-container');

      // Get the stored query from the search input
      this.queryString = this.searchInput.value;

      // If we have query stored in the session storage
      if (this.store.lastQuery !== '') {
        this.searchInput.value = this.store.lastQuery;
        this.queryString = this.store.lastQuery;

        this.store.lastQuery = '';
      }

      this.searchOnce();

      // Add listeners for the search input and pagination buttons
      this.paginationPrevious.addEventListener('click', () => this.goToPage(this.currentPage - 1));
      this.paginationNext.addEventListener('click', () => this.goToPage(this.currentPage + 1));
      this.searchInput.addEventListener('keyup', () => {
        this.currentPage = 0;
      });
    }

    /**
     * Update the hits container with the new items
     * @param {array} hits The results items received
     * @param {object} content The results content received
     */
    render(hits, content) {
      this.totalCountElement.innerText = content.nbHits;
      this.totalCountElement.parentElement.classList.remove('hide__default');
      this.paginationContainer.parentElement.classList.remove('hide__default');

      this.clearHits();

      const fragment = document.createDocumentFragment();
      // Set the numver of pages
      this.numberOfPages = content.nbPages;

      hits.forEach((vehicle) => {
        this.addVehicleTile(vehicle, fragment);
      });

      this.renderPagination();

      this.searchHits.appendChild(fragment);

      timer = global.setTimeout(() => {
        Array.from(document.querySelectorAll('.skeleton')).forEach((skeleton) =>
          skeleton.classList.remove('skeleton'),
        );
      }, 350);
    }

    /**
     * Get the now price group of a vehicle
     * @param {object} item The vehicle item
     * @param {string} priceTitle The price title to be displayed
     */
    static getNowPriceGroup(item, priceTitle) {
      return `
        <div class="price-group price-group__retail">
          <span class="price-title">${priceTitle}:</span>
          <span class="font--bold price">
            &pound;${numberWithCommas(item.Price)} ${item.TradePriceExtra.replace('=', '')}
          </span>
        </div>
      `;
    }

    /**
     * Get the was price group of a vehicle
     * @param {object} item The vehicle item
     * @param {string} property The property to get the was price from. Can be WasPrice or MRRP
     */
    static getWasPriceGroup(item, property) {
      const numberText = numberWithCommas(Math.round(item[property]));
      const tradePriceExtra = item.TradePriceExtra.replace('=', '');
      return `<div class="price-group price-group__was">
          <span class="price-title">Was:</span>
          <span class="price">
            &pound;${numberText} ${tradePriceExtra}
          </span>
        </div>`;
    }

    /**
     * Get the price group HTML of a vehicle item
     * @param {object} item The vehicle item to get the price group
     * @returns {HTMLDivElement}
     */
    getPriceGroup(item) {
      const priceGroup = document.createElement('div');
      priceGroup.className = 'price-group-container result-tile__price-group';

      let nowPriceGroup = '';
      let wasPriceGroup = '';

      // New vehicles price group
      // Check if the price on the road (wasPrice) is not the same as the now price
      if (
        item.New === 'Y' &&
        item.Price !== Math.round(item.MRRP) &&
        item.MRRP !== 0 &&
        item.MRRP !== '' &&
        hasProperty(item, 'MRRP')
      ) {
        wasPriceGroup = `<div class="price-group__grouped">${VehicleInstantSearchLanding.getWasPriceGroup(
          item,
          'MRRP',
        )}`;
        nowPriceGroup = `${VehicleInstantSearchLanding.getNowPriceGroup(item, 'Now')}</div>`;

        // Used vehicles price group
      } else if (
        item.Price !== Math.round(item.WasPrice) &&
        item.WasPrice !== 0 &&
        item.WasPrice !== '' &&
        hasProperty(item, 'WasPrice')
      ) {
        wasPriceGroup = `<div class="price-group__grouped">${VehicleInstantSearchLanding.getWasPriceGroup(
          item,
          'WasPrice',
        )}`;
        nowPriceGroup = `${this.getNowPriceGroup(item, 'Now')}</div>`;
      } else {
        nowPriceGroup = VehicleInstantSearchLanding.getNowPriceGroup(item, 'Our price');
      }

      priceGroup.insertAdjacentHTML('afterbegin', `${wasPriceGroup}${nowPriceGroup}`);

      return priceGroup;
    }

    /**
     * Add a vehicle to the hits container
     * @param {object} item The vehicle item to add to the tile list
     * @param {HTMLElement} container The element container to add the item
     */
    addVehicleTile(item, container) {
      const itemContainer = document.createElement('div');
      itemContainer.classList.add('xs-col-12', 's-col-6', 'm-col-4', 'l-col-3', 'skeleton');

      const vehicleTileStart = `<div class="tile__column result-tile ${
        item.VehicleStatus === 'SOLD' ? 'sold' : ''
      } ${item.VehicleStatus === 'RESERVED' ? 'reserved' : ''}">`;

      const vehicleImage = `
        <div class="result-tile__img skeleton__item">
        <span class="ribbon"></span>
          <a href="${getUrl(item.url)}">
            <div class="result-tile__img--bg" 
            style="background-image: url(${getPicture(item.PictureRefs, item.Category)});"></div>
          </a>
        </div>
      `;

      const vehicleHeader = `
        <header class="result-tile__header">
          <h4 class="result-tile__title line-height--small">
            <a href="${getUrl(item.url)}">
              ${`${item.Make} ${item.Model} ${item.Variant}`}
            </a>
          </h4>
        </header>
      `;
      const reservedItem = item.VehicleStatus === 'RESERVED' ? 'currently reserved.' : '';
      const itemSold = item.VehicleStatus === 'SOLD' ? 'now sold.' : reservedItem;
      const vehicleStatus = item.VehicleStatus !== 'SOLD' && item.VehicleStatus !== 'RESERVED';
      const sectionContent = vehicleStatus
        ? this.getPriceGroup(item).outerHTML
        : `<p>This vehicle is ${itemSold} </p>`;
      const itemMileage = item.Mileage > 0 ? `${numberWithCommas(item.Mileage)} Miles | ` : '';
      const vehicleBody = `
        <section class="result-tile__body">
        ${sectionContent}
          <p class="result-tile__summary">
            ${itemMileage}${item.Colour} | ${item.Transmission}
          </p>
          <p class="result-tile__location">
            <a href="/find-a-dealer/${item.DealerName.replace(/\s/g, '-').toLowerCase()}/">
              <i class="fas fa-map-marker-alt"></i>
              ${item.DealerName}
            </a>
          </p>
        </section>
      `;

      itemContainer.innerHTML = `${
        vehicleTileStart + vehicleImage + vehicleHeader + vehicleBody
      }</div>`;

      container.appendChild(itemContainer);
    }

    renderPagination() {
      // Hide or show the previous button
      if (this.currentPage === 0) {
        this.paginationPrevious.style.display = 'none';
      } else {
        this.paginationPrevious.removeAttribute('style');
      }

      const paginationItem = (page, string = '') => {
        if (this.currentPage === page - 1) {
          return `<span class="pagination__item text--primary"> ${page}</span>`;
        }
        return `<a class="pagination__item" onclick="goToPage(${page - 1})"> ${page + string}</a>`;
      };

      let paginationItems = '';

      if (this.currentPage > 3) {
        paginationItems += paginationItem(1, '...');
      }

      const items = pagination(
        this.numberOfPages,
        this.currentPage > 3 ? 6 : 7,
        this.currentPage + 1,
      );

      items.forEach((item) => {
        paginationItems += paginationItem(item);
      });

      // Hide or show the next button
      if (
        this.currentPage === this.numberOfPages ||
        this.currentPage + 1 === items[items.length - 1]
      ) {
        this.paginationNext.style.display = 'none';
      } else {
        this.paginationNext.removeAttribute('style');
      }

      this.paginationContainer.innerHTML = `${paginationItems}<span></span>`;
    }

    /**
     * Go to a different page
     * @param {number} page The page to go to
     */
    goToPage(page) {
      this.currentPage = page;
      this.searchOnce();
      global.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }

  class VehicleInstantSearchWidget extends VehicleInstantSearch {
    constructor(alSearch, container) {
      super(alSearch, container);

      // TODO: Revisit this when the new menu is permanent
      this.searchContainer = IS_NEW_NAV
        ? Array.from(document.querySelectorAll('.js-a-search-container')).find((element) =>
            element.closest('.main-nav__search'),
          )
        : document.querySelector('.js-a-search-container');

      this.searchButton = null;
    }

    init() {
      this.initSearchBox();
      global.document.addEventListener('click', this.documentClickHandler.bind(this));
    }

    /**
     * Update the dropdown container with the new items
     * @param {array} hits The results items received
     */
    render(hits) {
      this.clearHits();

      const fragment = document.createDocumentFragment();

      hits.forEach((vehicle) => {
        VehicleInstantSearchWidget.addDropdownItem(vehicle, fragment);
      });

      this.searchHits.appendChild(fragment);
    }

    /**
     * Initialize the search box
     */
    initSearchBox() {
      this.searchButton = document.querySelector('.js-a-search-button');

      this.searchInput.addEventListener('keyup', (event) => {
        if (event.key === 'Enter') {
          this.goToSearchPage();
        }

        if (!this.isDropdownOpened()) {
          this.openDropdown();
        }
      });

      this.searchInput.addEventListener('click', (event) => {
        if (event.target.value !== '') {
          this.openDropdown();

          if (this.searchContainer.querySelector('.js-standalone-search-loader')) {
            this.queryString = event.target.value;
            this.searchOnce();
          }
        }
      });

      this.searchButton.addEventListener('click', () => this.goToSearchPage());
    }

    goToSearchPage() {
      this.store.lastQuery = this.searchInput.value;
      global.location.href = '/search-results/';
    }

    openDropdown() {
      this.searchContainer.classList.add('typeahead-search--is-open');
    }

    closeDropdown() {
      this.searchContainer.classList.remove('typeahead-search--is-open');
    }

    isDropdownOpened() {
      return this.searchContainer.classList.contains('typeahead-search--is-open');
    }

    /**
     * Add a vehicle to the dropdown container
     * @param {object} item The vehicle item to add to the dropdown list
     * @param {HTMLElement} container The element container to add the item
     */
    static addDropdownItem(item, container) {
      const {
        url,
        _highlightResult: highlightResult,
        PictureRefs,
        Category,
        Price,
        Transmission,
        VehicleStatus,
        Year,
      } = item;

      // Create an item container to store all the vehicles
      const itemContainer = document.createElement('li');
      // itemContainer.classList.add('xs-col-12',
      // 'column--no-margin-bottom', 'algolia-search__dropdown-item');
      // itemContainer.classList.add('typahead-query__option');

      // Create the item inner container start which is wrapped in a link
      const itemContainerInnerStart = `<a href="${getUrl(url)}"class="typeahead-query__option">`;
      // const itemContainerInnerStart = `<a href="${item.vcj_vehicle_url}"class="row">`

      // Craete the item image container
      const itemImageContainer = `
        <div class="typeahead-query__img skeleton__item">
          <div class="result-tile__img--bg" 
          style="background-image: url(${getPicture(PictureRefs, Category)});"></div>
        </div>
    `;

      const vehicleSoldStatus =
        VehicleStatus === 'SOLD'
          ? '<span class="sold" style="vertical-align: middle;"><span class="ribbon" style="position: relative; border-radius: 4px;"></span></span>'
          : '';
      const vehicleReservedStatus =
        VehicleStatus === 'RESERVED'
          ? '<span class="reserved" style="vertical-align: middle;"><span class="ribbon" style="position: relative; border-radius: 4px;"></span></span>'
          : '';
      const numberCommas = numberWithCommas(Price);
      const vehicleSoldAndReservedStatus =
        VehicleStatus !== 'SOLD' && VehicleStatus !== 'RESERVED'
          ? `<span class="typeahead-query__price font--bold">&pound;${numberCommas}</span>`
          : '';
      // Create the item description
      const itemDescription = `
        <div class="typeahead-query__content">
          <h5 class="typeahead-query__title heading--grouped font--regular">${`${highlightResult.Make.value} ${highlightResult.Model.value} ${highlightResult.Variant.value}`}</h5>
          <div class="typeahead-query__summary">
          ${vehicleSoldStatus}
          ${vehicleReservedStatus}
          ${vehicleSoldAndReservedStatus}
          <span class="typeahead-query__year">
          ${Year > 0 ? `${Year}` : `${Transmission}`}</span>
          <span class="typeahead-query__location text--primary">
          ${highlightResult.DealerName.value}</span>
          </div>
        </div>
      `;

      // Close the a tag of the item inner container
      const itemContainerInnerEnd = '</a>';

      // Add the html to the item
      itemContainer.innerHTML =
        itemContainerInnerStart + itemImageContainer + itemDescription + itemContainerInnerEnd;

      // Append the item container to the container
      container.appendChild(itemContainer);
    }

    /**
     * A click handler for the document to close the dropdown when clicked outside
     * @param {MouseEvent} event The mouse event from the eventListener
     */
    documentClickHandler(event) {
      event.stopPropagation();

      const { classList } = event.target;

      if (
        !classList.contains('js-a-search-input') &&
        !classList.contains('js-a-search-dropdown') &&
        !classList.contains('js-a-search-hits') &&
        !classList.contains('js-a-search-mic')
      ) {
        this.closeDropdown();
      }
    }
  }

  global.vm.onload(() => {
    // TODO: Remove new nav condition when the new menu is permanent
    const isNavNav = global.localStorage.getItem('new_menu') === 'YES';

    global.vm
      .jsImport('https://cdn.jsdelivr.net/npm/algoliasearch@3/dist/algoliasearchLite.min.js')
      .then(() => {
        if (global.document.body.classList.contains('js-typeahead-search-landing')) {
          // eslint-disable-next-line no-unused-vars
          const searchInstance = new VehicleInstantSearchLanding(
            global.algoliasearch,
            document.querySelector('.js-site-container'),
          );
        } else if (isNavNav) {
          // eslint-disable-next-line no-unused-vars
          const searchInstance = new VehicleInstantSearchWidget(
            global.algoliasearch,
            document.querySelector('.js-site-main-nav'),
          );
        } else {
          // eslint-disable-next-line no-unused-vars
          const searchInstance = new VehicleInstantSearchWidget(
            global.algoliasearch,
            document.querySelector('.js-site-primary-nav'),
          );
        }
      });
  });
})(window);
