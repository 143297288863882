/**
 * Ribbons modules used on pages with vehicle tiles/gallery to rotate ribbons
 * with a fixed timeout and show ribbons in vehicle scrollers
 */
((window, document) => {
  const TIMEOUT = 3600;

  /**
   * Add a reserved ribbon to the gallery if the vehicle is reserved or sold
   */
  function addReservedRibbon() {
    const reserved = document.createElement('span');
    reserved.classList.add('ribbon');
    reserved.style.opacity = 1;

    const galleryContainer =
      document.querySelector('.gallery__main') || document.querySelector('.js-carousel');
    if (galleryContainer) {
      galleryContainer.appendChild(reserved);
    }
  }

  /**
   * Loop through the passed ribbons array every specified timeout
   *
   * @param {Element[][]} ribbons The ribbons to loop through. They should be two dimensional array in order to have only one timeout
   * @param {number} timeout The tim
   */
  function loopThroughRibbons(ribbons, timeout = TIMEOUT) {
    if (ribbons && ribbons.length) {
      // Main index for the ribbons counter
      let index = 0;

      // eslint-disable-next-line no-inner-declarations
      function loop() {
        let hasMultipleRibbons = false;

        // Loop through all the ribbons, get the ribbonSet, hide the current visible ribbon and show the next ribbon
        for (let i = 0; i < ribbons.length; i++) {
          const ribbonsSet = ribbons[i];

          // Hide the previous ribbon with a min index of 0 if we have more than 1 ribbon
          if (ribbonsSet.length > 1) {
            const currentRibbon = ribbonsSet[Math.max(0, (index - 1) % ribbonsSet.length)];
            currentRibbon.classList.remove('ribbon--visible');
            hasMultipleRibbons = true;
          }

          const newRibbon = ribbonsSet[index % ribbonsSet.length];
          newRibbon.classList.add('ribbon--visible');

          // If the ribbons only have 1 item then remove it from the array to have faster loop next time
          if (ribbonsSet.length <= 1) {
            ribbons.splice(i, 1);
            i--;
          }
        }

        index++;

        // Start the loop only if we have more than 1 ribbon in the sets of ribbons
        if (hasMultipleRibbons) {
          window.setTimeout(loop, timeout);
        }
      }

      loop();
    }
  }

  /**
   * Get the available ribbons on the page in a 2 dimensional array
   * [[ribbon1, ribbon2, ribbon3, ribbonN], [ribbon1, ribbon2, ribbonN]]
   *
   * It will loop through each ribbon if a ribbon has the same parent then they
   * are part of the same vehicle container and will be added together on the outter
   * array container. This is done in order to loop correctly for multiple containers
   * like scroller where there are more than one vehicle on the page with multiple ribbons
   * to loop through
   *
   * @returns {Element[][]}
   */
  function getAvailableRibbons() {
    const carouselColumn = document.querySelector('.js-carousel-column');
    const ribbonsList = document.querySelectorAll('.ribbon');
    const ribbonsListContainer = [];

    let prevParent;
    for (let i = 0, len = ribbonsList.length; i < len; i++) {
      const newParent = ribbonsList[i].parentElement;

      // If the ribbon has no data-title attribute or it is empty and has no children hide it and move to next itteration
      if (
        (ribbonsList[i]?.getAttribute('data-title') || '').trim() === '' &&
        (carouselColumn?.getAttribute('ribbons') || '').trim() === '' &&
        !ribbonsList[i]?.hasChildNodes()
      ) {
        ribbonsList[i].style.display = 'none';
        ribbonsList[i].classList.remove('vdp-ribbon');
        continue;
      }

      ribbonsList[i].classList.add('vdp-ribbon');

      // If the parent is the same with the previous parent then push the ribbon to the set of ribbons
      if (newParent === prevParent) {
        ribbonsListContainer[ribbonsListContainer.length - 1].push(ribbonsList[i]);
      } else {
        // Else a new parent is set and a new set should be pushed in the list
        prevParent = newParent;
        ribbonsListContainer.push([ribbonsList[i]]);
      }
    }

    return ribbonsListContainer;
  }

  /**
   * Initialize the ribbon loop if it is required or add the reserved ribbon to the vehicle
   */
  function initRibbonLoop() {
    const reservedOrSold = document.querySelector('.reserved') || document.querySelector('.sold');

    if (reservedOrSold) {
      addReservedRibbon();
    } else {
      const ribbons = getAvailableRibbons();
      loopThroughRibbons(ribbons);
    }
  }

  function initRibbonsContainer() {
    // Get all the ribbon containers and ribbon spans
    const ribbonContainers = document.querySelectorAll('.ribbon-container');
    const ribbonSpans = document.querySelectorAll('.js-dynamic-ribbon');

    if (ribbonSpans.length > 0 && ribbonContainers.length > 0) {
      // Loop through each ribbon span
      ribbonSpans.forEach((ribbonSpan, index) => {
        // Get the ribbon data from the span's data-ribbons attribute
        const ribbonData = JSON.parse(ribbonSpan.getAttribute('data-ribbons'));

        // Check if the ribbon data is an array with at least one element
        if (
          Array.isArray(ribbonData?.vehicleRibbonDtl) &&
          ribbonData?.vehicleRibbonDtl.length > 0
        ) {
          // Loop through each ribbon object in the array
          ribbonData.vehicleRibbonDtl.forEach((ribbon) => {
            // Get the ribbon class and label override
            const ribbonClass = ribbon?.ribbonClass.trim().replace(' ', '');
            const { ribbonLabelOverride } = ribbon;

            if (ribbonLabelOverride) {
              // Add the ribbon class to the corresponding ribbon container
              ribbonContainers[index].classList.add(ribbonClass);
              // Add the ribbon class and label override to the corresponding ribbon span
              ribbonSpan.classList.add('ribbon', 'ribbon--pill');

              // If ribbonLabelOverride includes 'Ready for Delivery' wrap it in a strong tag
              const isReadyForDelivery = ribbonLabelOverride.includes('Ready for Delivery');

              if (ribbonLabelOverride.includes('Ready for Delivery')) {
                wrapWithStrongTag(ribbonLabelOverride);
              }

              ribbonSpan.innerHTML = isReadyForDelivery
                ? wrapWithStrongTag(ribbonLabelOverride)
                : `${ribbonLabelOverride}`;
            }
          });
        }
      });
    }
  }

  function wrapWithStrongTag(label) {
    const getReadyForDelivery = label.slice(0, 18);
    const getNumAvailable = label.replace('Ready for Delivery', '');

    return `<strong>${getReadyForDelivery}</strong> ${getNumAvailable}`;
  }

  window.vm.onload(initRibbonLoop);
  document.addEventListener('DOMContentLoaded', initRibbonsContainer);
})(window, document);
