/**
 * Vertu motors is the main library which we will include any functionalities
 * that we need global wise more like helper functions similar to other libraries
 * out there
 */
((global, document) => {
  // //////////////////////////////////////////////////////////////
  // Polyfills                                                   //
  // //////////////////////////////////////////////////////////////
  global.requestAnimationFrame = global.requestAnimationFrame || global.setTimeout;

  // //////////////////////////////////////////////////////////////
  // Helper functions                                            //
  // //////////////////////////////////////////////////////////////

  /**
   * Check if a property is function
   * @param {function} fn The property to check
   */
  const isFunction = (fn) => typeof fn === 'function';

  /**
   * Check if the dom has been loaded
   */
  const isDomLoaded = () => document.readyState !== 'loading';

  /**
   * Check if the page has been loaded
   */
  const isPageLoaded = () => document.readyState === 'complete';

  /** Function to detect if the user is using IE or Edge browser
   * https://stackoverflow.com/questions/19999388/check-if-user-is-using-ie/#answer-21712356
   */
  function isIEEdge() {
    const ua = window.navigator.userAgent;

    const msie = ua.indexOf('MSIE ');
    if (msie > 0) {
      // IE 10 or older => return version number
      return parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10);
    }

    const trident = ua.indexOf('Trident/');
    if (trident > 0) {
      // IE 11 => return version number
      const rv = ua.indexOf('rv:');
      return parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10);
    }

    const edge = ua.indexOf('Edge/');
    if (edge > 0) {
      // Edge => return version number
      return parseInt(ua.substring(edge + 5, ua.indexOf('.', edge)), 10);
    }

    // other browser
    return false;
  }

  // //////////////////////////////////////////////////////////////
  // On page load handlers                                       //
  // //////////////////////////////////////////////////////////////

  // Store all the callbacks that need to be called once dom has been loaded
  const domCallbacks = [];
  // Store all the callback that need to be called after the page has loaded
  const loadCallbacks = [];

  /**
   * Call a function after the has been loaded
   * @param {function} fn The function to run
   */
  const onload = (fn) => {
    if (isFunction(fn)) {
      // If the page has loaded then call the function directly
      if (isDomLoaded()) {
        global.requestAnimationFrame(fn);
      } else {
        // Else add the functions to the load callback array
        domCallbacks.push(fn);
      }
    }
  };

  /**
   * Call a function after the has been loaded
   * @param {function} fn The function to run
   */
  const afterLoad = (fn) => {
    if (isFunction(fn)) {
      // If the page has loaded then call the function directly
      if (isPageLoaded()) {
        global.requestAnimationFrame(fn);
      } else {
        // Else add the functions to the load callback array
        loadCallbacks.push(fn);
      }
    }
  };

  /**
   * Handler to be called when the dom/page loads
   * @param {Function[]} functionArray - The array with functions to call
   */
  const completed = (functionArray) => {
    while (functionArray.length) {
      // Get the first callback function
      const fn = functionArray.shift();

      // Check if it is a function and call it
      if (isFunction(fn)) {
        global.requestAnimationFrame(fn);
      }
    }
  };

  /**
   * The window/document load handler
   */
  const domLoaded = () => {
    document.removeEventListener('DOMContentLoaded', domLoaded);
    global.removeEventListener('load', domLoaded);
    completed(domCallbacks);
  };

  /**
   * The window load event handler
   */
  const pageLoaded = () => {
    global.removeEventListener('load', pageLoaded);
    global.requestAnimationFrame(() => completed(loadCallbacks));
  };

  // Check if the document has already been loaded
  if (isDomLoaded()) {
    // Handle it asynchronously
    global.requestAnimationFrame(() => completed(domCallbacks));
  } else {
    // Add handlers for both domcontentloaded and window load event to make sure that
    // everything will run
    document.addEventListener('DOMContentLoaded', domLoaded);
    global.addEventListener('load', domLoaded);
  }

  // Check if the page has already been loaded
  if (isPageLoaded()) {
    // Handle it asynchronously
    global.requestAnimationFrame(() => completed(loadCallbacks));
  } else {
    // Add a listener for the load event
    global.addEventListener('load', pageLoaded);
  }

  // //////////////////////////////////////////////////////////////
  // Dynamic import JS/CSS                                       //
  // //////////////////////////////////////////////////////////////

  // Store all the imports that have already been fetched
  const fetchedScripts = {};

  const jsImport = (url, crossOrigin) => {
    if (fetchedScripts[url]) {
      return Promise.resolve();
    }

    return new Promise((resolve) => {
      const script = document.createElement('script');
      script.src = url;
      script.async = true;

      if (crossOrigin) {
        script.crossOrigin = crossOrigin;
      }

      script.onload = () => {
        fetchedScripts[url] = true;
        resolve();
      };

      script.onerror = (err) => {
        console.error(err);
        resolve();
      };

      document.head.appendChild(script);
    }).catch((error) => console.error(error));
  };

  const cssImport = (url) => {
    if (fetchedScripts[url]) {
      return Promise.resolve();
    }

    return new Promise((resolve) => {
      const link = document.createElement('link');
      link.rel = 'stylesheet';
      link.type = 'text/css';
      link.href = url;

      link.onload = () => {
        fetchedScripts[url] = true;
        resolve();
      };

      link.onerror = (err) => {
        console.error(err);
        resolve();
      };

      document.head.appendChild(link);
    });
  };

  /**
   * This will export and make functions available to global scope
   * so that we call them like
   * vm.onload(() => {})
   */
  global.vm = {
    onload,
    afterLoad,
    jsImport,
    cssImport,
    isIEEdge,
  };
})(window, document);
