// //////////////////////////////////////////////////////////////////////////
// Tabs
// //////////////////////////////////////////////////////////////////////////

((document) => {
  /**
   * Exclude cash id when syncing tabs content logic
   */
  const idToExclude = 'cash';

  /**
   * Initialize the tabs content and links
   * @param {HTMLElement} tabContainer The tab container to initialize the tab content
   */
  function initializeTabsContent(tabContainer) {
    if (tabContainer) {
      // Select all the tabs contents
      const tabContents = tabContainer.querySelectorAll('.tabs__content');

      // Loop through each of them and hide them
      for (let i = 0, len = tabContents.length; i < len; i += 1) {
        const tabContent = tabContents[i];
        if (tabContent.closest('.tabs-container:not(.tabs-container--js)') === tabContainer) {
          tabContent.style.display = 'none';
        }

        // Remove any empty tab content
        if (!tabContent.children.length && tabContent.innerText.trim() === '') {
          const tabNavItem = tabContainer.querySelector(`a[href="#${tabContent.id}"]`);
          tabNavItem.parentNode.removeChild(tabNavItem);

          tabContent.parentNode.removeChild(tabContent);
        }
      }
    }
  }

  /**
   * Hide all the tab content of a given container
   * @param {HTMLElement} tabContainer The tab container to hide the contents
   */
  function hideTabsContent(tabContainer) {
    // Select the tab contents and tab nav items
    const tabContents = tabContainer.querySelectorAll('.tabs__content');
    const tabNavigation = tabContainer.querySelector('.tabs__navigation');
    const tabNavItems = tabNavigation
      ? tabNavigation.querySelectorAll('.tabs__item.is-active')
      : null;

    // Used on the new VDP's to stop content fickering
    if (tabNavigation && tabNavigation.classList.contains('hide__default')) {
      tabNavigation.classList.remove('hide__default');
    }

    // Loop through each tab content and hide them
    for (let i = 0, len = tabContents.length; i < len; i += 1) {
      const tabContent = tabContents[i];
      if (tabContent.closest('.tabs-container:not(.tabs-container--js)') === tabContainer) {
        tabContent.style.display = 'none';
      }
    }

    if (tabNavItems) {
      // Loop through each active tab item and remove the class
      for (let i = 0, len = tabNavItems.length; i < len; i += 1) {
        tabNavItems[i].classList.remove('is-active');
      }
    }
  }

  /**
   * Show a tab content of a given container based on the id
   * @param {HTMLElement} tabContainer The tab container of the tab to show
   * @param {string} id The id of the tab to show, should contain the # as well
   */
  function showTabContent(tabContainer, id) {
    if (tabContainer) {
      /**
       * The target element that will be opened
       * @type {Element}
       */
      let target = null;

      // Hide all the contents
      hideTabsContent(tabContainer);

      // If the id is passed
      if (id) {
        // Check if the slector is correct before proceeding
        if (!/^#[a-z][a-z0-9-_]*$/i.test(id) || !tabContainer.querySelector(`a[href="${id}"]`)) {
          return;
        }

        // Activate the selected content/tab item based on the id
        tabContainer.querySelector(`a[href="${id}"]`).classList.add('is-active');
        target = tabContainer.querySelector(id);

        const allTargets = tabContainer.querySelectorAll(id);
        allTargets.forEach((t) => {
          t.style.display = 'block';
        });
      } else {
        // Else activate the first content/item
        const tabFirstChild = tabContainer.querySelector('.tabs__item:first-child');
        if (tabFirstChild) {
          tabFirstChild.classList.add('is-active');
        }

        try {
          const thankYouMessage = document.querySelector('.js-thank-you');

          if (tabContainer) {
            if (thankYouMessage) {
              // eslint-disable-next-line max-len
              thankYouMessage.parentElement.parentElement.parentElement.parentElement.style.display =
                'block';
              const thankYouEl =
                thankYouMessage.parentElement.parentElement.parentElement.parentElement.id;
              if (
                tabContainer.querySelector(`a[href="#${thankYouEl}"]`) !==
                tabContainer.querySelector('.tabs__item:first-child')
              ) {
                tabContainer.querySelector('.tabs__item:first-child').classList.remove('is-active');
              }
              tabContainer.querySelector(`a[href="#${thankYouEl}"]`).classList.add('is-active');
            } else {
              tabContainer.querySelectorAll('.tabs__content')[0].style.display = 'block';
            }
          }
        } catch (err) {
          // eslint-disable-next-line no-console
          console.error(err);
        }
      }

      /**
       * Fire a new custom event with the target
       * that is being activated in order to reset carousel track
       */
      window.dispatchEvent(
        new CustomEvent('activeTab', {
          detail: {
            container: tabContainer,
            target,
          },
        }),
      );
    }
  }

  /**
   * Initialize the tabs functionality at a tab container
   * @param {HTMLElement} tabContainer The tab container to initialize
   */
  function initializeTabs(tabContainer) {
    // Get all possible default tabs
    let defaultTabContent = tabContainer.querySelectorAll('.tabs__item');

    // Filter out tabs that are hidden by default
    defaultTabContent = Array.from(defaultTabContent).filter((tab) => {
      const visible = getComputedStyle(tab, null).display !== 'none';
      return visible;
    });

    // Show first displayed tab w/o "display: none"; or fallback to is-active
    defaultTabContent =
      defaultTabContent.length > 0
        ? tabContainer.querySelector('[class*="thisOne"]') ||
          tabContainer.querySelector('.is-selected') ||
          tabContainer.querySelector('.is-active')
        : defaultTabContent[0];

    // If it's a van and has a lease offer tab that's visible, make it the default
    const leaseTab = document.querySelector('.js-tabs__item--lease:not(.hide__default)');
    const isVanType = /-van-/.test(window.location.href);

    if (leaseTab && isVanType) {
      defaultTabContent = leaseTab;
    }

    // Initialize all the tabs content and tab links
    initializeTabsContent(tabContainer);

    if (defaultTabContent) {
      const defaultId =
        defaultTabContent.tagName === 'A'
          ? defaultTabContent.getAttribute('href')
          : `#${defaultTabContent.id}`;
      // Show the default tab if it exists
      showTabContent(tabContainer, defaultId);
    } else {
      // Show the first child
      showTabContent(tabContainer);
    }
  }

  /**
   * Click handler for the document click listener
   * @param {MouseEvent} event The event that occured
   */
  function clickHandler(event) {
    // Get the clicked element
    let clickedEl = event.target;
    const primaryNav = document.querySelector('.js-primary-nav');

    // Loop through all the parent elements to check if an element was clicked
    while (clickedEl) {
      // if the clicked element is a dropdown item
      if (clickedEl.classList && clickedEl.classList.contains('dropdown__item')) {
        const tabContainer = document.querySelector('.tabs-container:not(.tabs-container--js)');
        showTabContent(tabContainer, clickedEl.getAttribute('href'));
        document.documentElement.style.scrollBehavior = 'smooth';
        if (primaryNav) {
          const primaryNavHeight = primaryNav.offsetHeight;
          document.documentElement.style.scrollPaddingTop = `${primaryNavHeight}px`;
        }
      }
      // If the clicked element is a tab item and is not active
      if (clickedEl.classList && clickedEl.classList.contains('tabs__item')) {
        // Prevent default as it is a link and scrolls on the div with the same id
        event.preventDefault();

        if (!clickedEl.classList.contains('is-active')) {
          // Get the closest tab container and show the selected tab
          const tabContainer = clickedEl.closest('.tabs-container:not(.tabs-container--js)');
          // Check whether the tab container contains the synced class
          const tabContainerSynced = document.querySelector('.js-tabs-container--synced');
          // If teh synced class is present, then show the tab content
          // on each tab container
          if (tabContainerSynced && clickedEl.getAttribute('href') !== `#${idToExclude}`) {
            const tabContainers = document.querySelectorAll(
              '.tabs-container:not(.tabs-container--js)',
            );
            // eslint-disable-next-line no-restricted-syntax
            for (const container of tabContainers) {
              showTabContent(container, clickedEl.getAttribute('href'));
            }
          } else {
            showTabContent(tabContainer, clickedEl.getAttribute('href'));
          }
        }
      }

      clickedEl = clickedEl.parentNode;
    }
  }

  // Display the correct content for the homepage buy or sell widget
  function contentHandler(element, checked) {
    if (checked) {
      // Swap the text content of the elements
      element.getElementsByTagName('a')[0].innerHTML = 'Sell';
      element.getElementsByTagName('a')[1].innerHTML = 'Buy';
      // Shows/hides the right content
      document.getElementById('buy').style.display = 'none';
      document.getElementById('sell').style.display = 'block';
    } else {
      // Swap the text content of the elements
      element.getElementsByTagName('a')[0].innerHTML = 'Buy';
      element.getElementsByTagName('a')[1].innerHTML = 'Sell';
      // Shows/hides the right content
      document.getElementById('buy').style.display = 'block';
      document.getElementById('sell').style.display = 'none';
    }
  }

  /**
   *Handles the checkbox state and triggers the right content to render for the Homepage Buy or Sell
   */
  function widgetClickHandler(event) {
    const widgetCheckbox = document.querySelector('.widget-switch__checkbox');
    const widgetSwitch = document.querySelector('.widget-switch__switch');
    const clickedEl = event.target;

    if (clickedEl === widgetSwitch || clickedEl.parentElement === widgetSwitch) {
      contentHandler(widgetSwitch, (widgetCheckbox.checked = !widgetCheckbox.checked));
      event.preventDefault();
    }
  }

  /**
   * Build the tab bar
   * @param {*} tabsContainers
   */
  function buildTabs(tabsContainers) {
    for (let i = 0, len = tabsContainers.length; i < len; i += 1) {
      initializeTabs(tabsContainers[i]);
    }
  }

  /**
   * Synchonise the tabs to the targeted content to allow highlighting on scroll
   */
  function syncTabs() {
    const tabs = [];
    document.querySelectorAll('.tabs__item.js-smooth-scroll-link').forEach((tab) => {
      const tabName = tab.hash.slice(1);
      tabs.push({ tab: tabName, link: `a[href="#${tabName}"]` });
    });
    tabs.reverse();

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          Array.from(document.querySelectorAll('.tabs__item.js-smooth-scroll-link')).forEach((el) =>
            el.classList.remove('is-active'),
          );
          const targetTab = tabs.find((tab) => tab.tab === entry.target.id);
          document.querySelector(targetTab.link).classList.add('is-active');
        });
      },
      {
        threshold: 1,
      },
    );

    // eslint-disable-next-line no-restricted-syntax
    for (const tab of tabs) {
      observer.observe(document.getElementById(tab.tab));
    }
  }

  // Initialize everything once the dom content has been loaded to prevent errors
  vm.onload(() => {
    // Select the tabs containers
    const tabsContainers = document.querySelectorAll('.tabs-container:not(.tabs-container--js)');
    const buySellWidget = document.querySelector('.js-buy-sell');
    const billboardContent = document.querySelector('.js-billboard-content');

    const bilboardContentCaption = document.querySelector('.billboard-content__caption');
    // Checks if the page has buy or sell
    if (buySellWidget && billboardContent && bilboardContentCaption) {
      document.addEventListener('click', widgetClickHandler);

      if (window.innerWidth < 880) {
        billboardContent.style.cssText = 'display: flex; height: 100%;';
        bilboardContentCaption.style.flex = '1';
        bilboardContentCaption.style.alignSelf = 'end';
        if (
          // eslint-disable-next-line operator-linebreak
          buySellWidget.getElementsByTagName('IMG') &&
          buySellWidget.getElementsByTagName('IMG')[0]
        ) {
          buySellWidget.getElementsByTagName('IMG')[0].style.maxWidth = '180px';
        }
      }
      window.addEventListener('resize', () => {
        if (window.innerWidth < 880) {
          billboardContent.style.cssText = 'display: flex; height: 100%;';
          bilboardContentCaption.style.flex = '1';
          bilboardContentCaption.style.alignSelf = 'end';
          if (
            // eslint-disable-next-line operator-linebreak
            buySellWidget.getElementsByTagName('IMG') &&
            buySellWidget.getElementsByTagName('IMG')[0]
          ) {
            buySellWidget.getElementsByTagName('IMG')[0].style.maxWidth = '180px';
          }
        } else {
          billboardContent.style.cssText = 'display: block;';
          bilboardContentCaption.style.flex = null;
          bilboardContentCaption.style.alignSelf = null;
        }
      });
    }

    const buildTabsPromise = new Promise((myResolve) => {
      buildTabs(tabsContainers);
      myResolve('OK');
    });

    buildTabsPromise.then(() => {
      if (document.getElementsByClassName('js-tabs-sync').length > 0) {
        setTimeout(() => {
          syncTabs();
        }, 200);
      }
    });

    // Add the global click listener
    document.addEventListener('click', clickHandler);
  });

  // Make the initialise function global
  window.tabs = {
    initializeTabs,
  };
})(document);
