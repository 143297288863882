// Accordion

/**
 * Like jQuery's slideDown function - uses CSS3 transitions
 * @param  {Node} elem Element to show and hide
 */
// function slideDown(elem) {
//   elem.style.maxHeight = '1000px';
// We're using a timer to set opacity = 0 because setting max-height = 0 doesn't hide the element.

//   elem.style.opacity = '1';
// }
/**
 * Slide element up (like jQuery's slideUp)
 * @param  {Node} elem Element
 * @return {[type]}      [description]
 */

// function slideUp(elem) {
//   elem.style.display = 'none';
// }
/**
 * Call once after timeout
 * @param  {Number}   seconds  Number of seconds to wait
 * @param  {Function} callback Callback function
 */

// function once(seconds, callback) {
//   var counter = 0;
//   var time = window.setInterval(function () {
//     counter++;

//     if (counter >= seconds) {
//       callback();
//       window.clearInterval(time);
//     }
//   }, 400);
// }

function init() {
  const allPanels = [].slice.call(document.querySelectorAll('.accordion__content'));
  allPanels.forEach((panel) => {
    panel.style.display = 'none';
  });
  const accordionItems = [].slice.call(document.querySelectorAll('.accordion__item'));
  accordionItems.forEach((accordionItem) => {
    // eslint-disable-next-line func-names
    accordionItem.addEventListener('click', function () {
      if (this.classList.contains('accordion__item--is-active')) {
        this.classList.remove('accordion__item--is-active');
        this.nextElementSibling.style.display = 'none';
      } else {
        this.classList.add('accordion__item--is-active');
        allPanels.forEach((panel) => {
          if (panel.style.display === 'block') {
            panel.style.display = 'none'; // First previousSibling is newline, next previousSibling is accordion item.

            if (
              panel.previousSibling.previousSibling.classList.contains('accordion__item--is-active')
            ) {
              panel.previousSibling.previousSibling.classList.remove('accordion__item--is-active');
            }
          }
        });
        this.nextElementSibling.style.display = 'block';
      }
    });
  });

  /* Default accordion to 'open' if it
    has the class 'accordion__item--open' on 'accordion__item' */
  const openAccordion = document.querySelector('.accordion__item--open');
  if (openAccordion) {
    openAccordion.classList.add('accordion__item--is-active');
    openAccordion.nextElementSibling.style.display = 'block';
  }
}

vm.onload(() => init());
