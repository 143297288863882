/**
 * MyCarFeatures v3, used with new layout of primary nav
 */
((window, document) => {
  /**
   * Initialise the dataLayer
   */
  window.dataLayer = window.dataLayer || [];

  /**
   * The api uri to make the calls for handle my car functionalities
   */
  const API_URI = '/content/api/ajax_mbs_search.p';

  const LOGIN_URI = '/login-register-v2/?ajax=yes';
  const LOGIN_REGISTER_URI = '/js/modules/dist/login-register.js';

  const BRAND_CONFIGS = window.location.href.includes('vertumotorcycles')
    ? {
        appTitle: 'My Motorcycle',
        vehicle: 'motorcycle',
        appBaseHref: 'my-motorcycle',
        appSavedVehiclesHref: 'searches-alerts/saved-cars',
      }
    : {
        appTitle: 'My Car',
        vehicle: 'car',
        appBaseHref: 'my-car',
        appSavedVehiclesHref: 'searches-alerts/saved-cars',
      };

  /**
   * Store the login handler in order to remove it from the event listeners
   * @type {() => void}
   */
  let loginHandler;

  /**
   * Store the loginRegisterModalId in order to check for the ecomm journey
   */
  const loginRegisterModalId = 'login-register-modal';

  /**
   * The login register wrapper in order to hide when on ecomm journey to display different content
   * @type {Element}
   */
  let loginRegisterWrapper;

  /**
   * Convert params object to form data
   *
   * @private
   * @param {{[key: string]: string | number}} params The params to convert
   */
  function paramsToFormData(params) {
    const formDataParams = new FormData();
    const objKeys = Object.keys(params);

    for (let i = 0, len = objKeys.length; i < len; i++) {
      formDataParams.append(objKeys[i], params[objKeys[i]]);
    }

    return formDataParams;
  }

  /**
   * Load the login page and login-register functionality
   *
   * @private
   * @returns {Promise<string>}
   */
  function loadLoginPage() {
    return new Promise((resolve, reject) => {
      axios
        .get(LOGIN_URI)
        .then(({ data }) => {
          if (window.MyCarLogin === undefined) {
            window.vm.jsImport(LOGIN_REGISTER_URI).then(() => {
              resolve(data);
            });
          }
        })
        .catch(reject);
    });
  }

  /**
   * Create the login modal and initialize login register functionality
   *
   * @private
   * @param {string} modalContent The modal content to create the modal with
   * @param {string} modalFooter The footer content (everything below the buttons/input fields)
   * @returns {Promise<void>}
   */
  function createLoginModal(modalContent = '', modalFooter = '') {
    if (
      document.querySelector(`#${loginRegisterModalId}`) ||
      document.querySelector('#mycar-modal')
    ) {
      return Promise.resolve();
    }

    // If the login-register form is on the page then display an info and scroll into view
    if (document.querySelector('.js-login-register-form')) {
      const alertInfo = document.querySelector('.js-alert-info-container');
      alertInfo.querySelector('p').textContent =
        'Please log in or register in order to view your saved vehicles.';
      alertInfo.classList.remove('hide__default');
      alertInfo.scrollIntoView();
      window.scrollBy(0, -(alertInfo.scrollHeight * 2));
      return Promise.resolve();
    }

    const modalFragment = document.createDocumentFragment();

    const modal = document.createElement('div');
    modal.className = 'modal login-register-modal';
    modal.id = loginRegisterModalId;

    return new Promise((resolve, reject) => {
      loadLoginPage()
        .then((data) => {
          modal.innerHTML = `
          <header class="modal__header">
            <div class="modal__controls">
              <div class="modal__close">
                <svg viewBox="0 0 40 40">
                  <path d="M 10,10 L 30,30 M 30,10 L 10,30"></path>
                </svg>
              </div>
            </div>
          </header>
          <div class="modalContent">
            ${modalContent}
            <div>${data}</div>
            ${modalFooter}
          </div>
        `;

          modalFragment.appendChild(modal);
          document.body.appendChild(modalFragment);

          setTimeout(() => {
            window.MyCarLogin.init();
            resolve();
          }, 16);
        })
        .catch(reject);
    });
  }

  /**
   * A callback to be used when the login modal closes
   *
   * @private
   */
  function loginModalCloseCallback() {
    window.removeEventListener('onLogIn', loginHandler);

    if (loginRegisterWrapper) {
      loginRegisterWrapper.classList.remove('hide__default');
      document.querySelector('.js-login-modal-content').classList.add('hide__default');
    }
  }

  /**
   * Handle the user log in
   *
   * @public
   * @param {() => void} cb The callback to call after the user has logged in
   * @param {string} modalContent The modal content to create the modal with
   * @param {string} modalFooter The footer content of the modal (everything
   * below the input fields/buttons)
   */
  function handleUserLogIn(cb, modalContent, modalFooter = '') {
    window.removeEventListener('onLogIn', loginHandler);

    const vehicleSearchModal = document.querySelector('#mycar-modal');

    loginHandler = () => {
      cb();
      window.ModalEffects.closeModal(
        vehicleSearchModal ? 'mycar-modal' : loginRegisterModalId,
        loginModalCloseCallback,
      );
    };

    window.addEventListener('onLogIn', loginHandler);

    createLoginModal(modalContent, modalFooter)
      .then(() => {
        // Ecomm modal
        if (loginRegisterWrapper) {
          loginRegisterWrapper.classList.add('hide__default');
          document.querySelector('.js-login-modal-content').classList.remove('hide__default');
        }

        window.ModalEffects.openModal(
          vehicleSearchModal ? 'mycar-modal' : loginRegisterModalId,
          loginModalCloseCallback,
        );
      })
      .catch(window.vm.debug.error);
  }

  /**
   * Save a car to the customer's my-car account
   *
   * @private
   * @param {string} Action The action for the API call
   * @param {number} StockRef The vehicle stock ref
   * @param {number} custRef The customer's ref
   * @param {string} custHash The customer's hash
   */
  function saveCar(Action, StockRef, custRef, custHash) {
    if (!custRef || !custHash) {
      [custRef, custHash] = window.Cookie.get('mcSess').split('-');
    }

    const params = paramsToFormData({
      myFormat: 'Angular',
      Action,
      custRef,
      custHash,
      StockRef,
    });

    axios
      .post(API_URI, params)
      .then(() => {
        document.querySelector(`#savedRef${StockRef}`).classList.toggle('saved');
        window.dataLayer.push({
          category: 'User Interaction',
          action: 'Click VDP CTA',
          label: 'Save',
        });
      })
      .catch((err) => {
        window.vm.debug.error(err);
      });
  }

  /**
   * Handle save car functionality to be called by click listeners
   *
   * @public
   * @param {string} action The action for the API call
   * @param {number} stockRef The vehicle's stock ref
   * @param {number} custRef The customer's ref
   * @param {string} custHash The customer's hash
   */
  function handleSaveCar(action, stockRef, custRef, custHash) {
    if (window.Cookie.get('mcSess') !== '') {
      saveCar(action, stockRef, custRef, custHash);
      return;
    }

    handleUserLogIn(() => saveCar(action, stockRef, custRef, custHash));
  }

  /**
   * Handles a click of a MyCar navbar option.
   *
   * If app/routerSubject is present, prevent default and let
   * the app handle the navigation to utilise SPA capabilities.
   *
   * @public
   * @param event
   */
  function handleNavigationClick(event) {
    if (typeof window.routerSubject !== 'undefined') {
      event.preventDefault();
      const relativeUrl = event.target
        .getAttribute('href')
        .split('/')
        .filter((s) => !['', 'my-car', 'my-motorcycle'].includes(s))
        .join('/');
      window.routerSubject.next(relativeUrl);
    }
  }

  /**
   * Toggle the comparedRef element class
   * @param {number} stockRef The vehicle's stock ref
   * @param {'add' | 'remove'} action The action to call on the element
   */
  function toggleCompareRef(stockRef, action) {
    // const jsCompareTrigger = document.querySelector('.js-compare-trigger');

    // Get the compared ref element and toggle it's class
    const comparedRef = document.querySelector(`#compareRef${stockRef}`);
    if (comparedRef) {
      comparedRef.classList[action]('compared');
    }

    // If compare trigger is on the page for old website layout
    // TODO: Update or Remove when new primary-nav is globalized
    // if (jsCompareTrigger) {
    //   const jsCompareLabel = jsCompareTrigger.querySelector('.js-compare-num');
    //   const regex = /\d+/;
    //   const compareNum = parseInt(jsCompareLabel.textContent.match(regex)[0]);

    //   let newAmount;

    //   // When compare car is added
    //   if (action === 'add') {
    //     if (compareNum < 4) {
    //       newAmount = compareNum + 1;
    //     }
    //   } else {
    //     // When compare car is removed
    //     const currentComparedVehicleListEl = document.querySelector(`.compareListRef${stockRef}`);

    //     if (currentComparedVehicleListEl) {
    //       currentComparedVehicleListEl.style.display = 'none';
    //     }

    //     Array.from(document.querySelectorAll('.compareListRef')).forEach(el => el.style.display = 'none');

    //     if (compareNum > 0) {
    //       newAmount = compareNum - 1;
    //     }
    //   }

    //   jsCompareLabel.textContent = jsCompareLabel.textContent.replace(regex, newAmount);
    //   jsCompareTrigger.style.display = newAmount > 0 ? 'inline-block' : 'none';

    //   if (newAmount <= 0) {
    //     window.ModalEffects.closeModal(jsCompareTrigger.getAttribute('data-modal'));
    //   }

    //   // If the compareFigure is on the global scope update the hamburger notification pills
    //   if (typeof window.compareFigure !== 'undefined') {
    //     window.compareFigure = newAmount;
    //     updateHamburgerNotification();
    //   }
    // }
  }

  /**
   * Make an API request to compare a vehicle and return the required data
   *
   * @private
   * @param {string} Action The action for the API call
   * @param {number} StockRef The vehicle's stock ref
   */
  function compareCarRequest(Action, StockRef) {
    const params = paramsToFormData({
      myFormat: 'Angular',
      Action,
      StockRef,
    });

    return axios
      .post(API_URI, params)
      .then(({ data }) => (data.myData ? data.myData.myResultData[0] : null));
  }

  /**
   * Handle compare car functionality to be called by click listeners
   *
   * @public
   * @param {string} Action The action for the API call
   * @param {number} StockRef The vehicle's stock ref
   */
  function compareCar(Action, StockRef) {
    compareCarRequest(Action, StockRef)
      .then((data) => {
        if (!data) {
          throw new Error('[compareCar] No data were retrieved');
        }

        // If the vehicle was added succesfully
        if (data.Compared === 'Compared') {
          toggleCompareRef(StockRef, 'add');
        } else {
          // If the vehicle was removed
          toggleCompareRef(StockRef, 'remove');
        }
      })
      .catch(window.vm.debug.error);
  }

  /**
   * Handle the saved button click on new primary nav
   *
   * @public
   */
  function handleSavedButton() {
    if (window.Cookie.get('mcSess') !== '') {
      window.location.href = `/${BRAND_CONFIGS.appBaseHref}/#/${BRAND_CONFIGS.appSavedVehiclesHref}`;
      return;
    }

    handleUserLogIn(handleSavedButton);
  }

  /**
   * Toggle the element's visibility based on the amount it has
   * @param {HTMLElement} element The element to toggle visibility
   * @param {boolean} hasMargin If the element needs margin when visible
   */
  function toggleElementVisibility(element, hasMargin) {
    if (element) {
      const amount = element.textContent.match(/\d+/)[0];
      const zero = amount === '0';

      element.style.display = zero ? 'none' : 'inline-block';
      if (hasMargin) {
        element.classList[zero ? 'remove' : 'add']('margin-right-10');
      }
    }
  }

  /**
   * Handle the recent trigger clicks
   * @param {MouseEvent} event The event that occured
   */
  function handleRecentTriggerClick(event) {
    event.preventDefault();

    window.ModalEffects.openModal(this.getAttribute('data-modal'));

    axios
      .get('/recently-viewed/?ajax=yes')
      .then((res) => {
        document.querySelector('.panelRecent').innerHTML = res.data;
      })
      .catch((err) => {
        console.error(err);
      });
  }

  /**
   * Initialize the recent panel with it's triggers
   * @param {HTMLElement[]} recentTriggers The recent triggers array
   */
  function initRecentPanel(recentTriggers) {
    recentTriggers.forEach((trigger) => {
      toggleElementVisibility(trigger);
      trigger.addEventListener('click', handleRecentTriggerClick);
    });
  }

  /**
   * Handle the compare trigger clicks
   * @param {MouseEvent} event The event that occured
   */
  function handleCompareTriggerClick(event) {
    event.preventDefault();

    window.ModalEffects.openModal(this.getAttribute('data-modal'));

    axios
      .get('/car-compare/?ajax=yes')
      .then((res) => {
        document.querySelector('.compare').innerHTML = res.data;
        document.querySelectorAll('.compareListRef').forEach((el) => (el.style.display = 'none'));
      })
      .catch((err) => {
        console.error(err);
      });
  }

  /**
   * Initialize the compare panel with it's triggers
   * @param {HTMLElement[]} compareTriggers THe compare triggers array
   */
  function initComparePanel(compareTriggers) {
    compareTriggers.forEach((trigger) => {
      toggleElementVisibility(trigger);
      trigger.addEventListener('click', handleCompareTriggerClick);
    });
  }

  /**
   * Load the compare recent panels and add events to open/close the modal
   */
  function loadCompareRecentPanels() {
    const recentTriggers = Array.from(document.querySelectorAll('.js-recent-trigger'));
    const compareTriggers = Array.from(document.querySelectorAll('.js-compare-trigger'));

    initRecentPanel(recentTriggers);
    initComparePanel(compareTriggers);
  }

  /**
   * Update the hamburger notification pills based on the compare/recent values
   */
  function updateHamburgerNotification() {
    let hamburgerValue = 0;

    const hamburgerNotification = document.querySelector('.hamburger-notification');

    if (typeof window.recentFigure !== 'undefined' && typeof window.compareFigure !== 'undefined') {
      if (window.recentFigure >= 1) {
        hamburgerValue += 1;
      }

      if (window.compareFigure >= 1) {
        hamburgerValue += 1;
      }

      if (hamburgerNotification) {
        hamburgerNotification.style.display = hamburgerValue <= 0 ? 'none' : 'block';
        hamburgerNotification.children[0].innerText = `${hamburgerValue}`;
      }

      window.hamburgerValue = hamburgerValue;
    } else {
      console.warn('RecentFigure or CompareFigure is missing');
    }
  }

  /**
   * Load login-register modal when the page loads
   * @param {() => void} cb The callback to call when the user logged in
   */
  function onPageLoad(cb = () => {}) {
    if (window.Cookie.get('mcSess') === '') {
      handleUserLogIn(cb);
    }
  }

  window.vm.onload(() => {
    // Check if the ecomm login modal is in the page
    // const ecommLoginModal = document.querySelector('#login-register-modal');
    // if (ecommLoginModal) {
    //   loginRegisterModalId = 'login-register-modal';
    //   ecommLoginModal.querySelector('.js-login-modal-content').classList.add('hide__default');
    // }

    // Check if the user is logged in
    // TODO: Update or Remove when new primary-nav is globalized
    const signOutAction = document.querySelector('#sign-out');
    if (window.Cookie.get('mcSess') !== '') {
      if (signOutAction) {
        signOutAction.classList.remove('hide__default');
      }
    } else {
      // If not add an event listener to display it when user logs in
      window.addEventListener('onLogIn', function onLoginHandle() {
        if (signOutAction) {
          signOutAction.classList.remove('hide__default');
        }
        window.removeEventListener('onLogIn', onLoginHandle);
      });
    }

    updateHamburgerNotification();
    loadCompareRecentPanels();
  });

  window.MyCarFeatures = {
    handleSaveCar,
    handleSavedButton,
    handleNavigationClick,
    compareCar,
    handleUserLogIn,
    onPageLoad,
  };
})(window, document);
