((global) => {
  /** @tet comment
   * @class Cookie
   * @description This class is for handling the cookies within the website
   * @file global/js/modules/cookie.js
   */
  class Cookie {
    /**
     * Get a cookie value based on the cookie name
     * @param {string} name - The cookie name to get
     * @return {string} - The cookie value
     */
    static get(name) {
      // The current cookie value
      const value = `; ${document.cookie}`;
      // Split the values in an array of two
      const cookieArr = value.split(`; ${name}=`);
      // If two values were found return the cookie value
      if (cookieArr.length >= 2) {
        return cookieArr.pop().split(';').shift();
      }

      // Return an empty value
      return '';
    }

    /**
     * Set a cookie name, value and expiration time
     * @param {string} name - The cookie name to set
     * @param {string|number} value - The cookie value to set
     * @param {number} [days] - The expiration time in days
     */
    static set(name, value, days) {
      let expires = '';

      // Check that the name and value are defined
      if (!name && typeof value === 'undefined') {
        global.vm.debug.warn('Cookie:set - name and value is required to set a cookie.');
        return;
      }

      // Check if expiry days value has been defined
      if (days) {
        const date = new Date();
        date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
        expires = `; expires=${date.toUTCString()}`;
      }

      // Set the cookie
      document.cookie = `${name}=${value}${expires}; path=/`;
    }

    /**
     * Delete a cookie based on it's name
     * @param {string} name - The cookie name to delete
     */
    static delete(name) {
      document.cookie = `${name}= ; expires=${new Date()}; path=/`;
    }
  }

  global.Cookie = Cookie;
})(window);
