/**
 * Analytics JS for global tracking and dataLayer
 * https://app.asana.com/0/848875433820573/1198948594450087/f
 */
((window, document) => {
  /**
   * Initialise the dataLayer
   */
  window.dataLayer = window.dataLayer || [];

  /**
   * Store all elements with class js-cta-track
   */
  const clickables = document.querySelectorAll('.js-cta-track');
  const eventType = 'vdpCTA';

  /**
   * Store the part ex tracking object in a variable
   */
  const partExTrackingObj = JSON.parse(localStorage.getItem('partExTrackingObject'));

  if (window.dataLayer) {
    if (partExTrackingObj) {
      // Push the part ex status and value if the part ex object is present
      window.dataLayer.push({
        partExStatus: partExTrackingObj.partExStatus,
        partExValue: partExTrackingObj.partExValue,
      });
    }

    if (clickables) {
      clickables.forEach((cta) => {
        cta.addEventListener('click', () => {
          // Push the clicked cta data-event if it is present.
          if (cta.dataset.event) {
            window.dataLayer.push({
              event: eventType,
              cta: cta.dataset.event,
            });
          }
        });
      });
    }
  }
})(window, document);
