// Primary Nav

((window, document) => {
  const robertsMessage = document.querySelector('.js-message-content');
  const openDrawer = document.querySelector('.js-open-drawer-left');
  const primaryNav = document.querySelector('.js-site-primary-nav');
  const navContainer = document.querySelector('.site-nav-container');
  const nav = document.querySelector('.js-primary-nav');
  // const brandLogoContainer = document.querySelector('.primary-nav__logo-container');
  const typeaheadSearch = document.querySelector('.primary-nav__typeahead-search');
  const mobileView = window.matchMedia('(max-width: 640px)');
  const desktopView = window.matchMedia('(min-width: 1081px)');
  const searchInput = document.querySelector('.js-a-search-input');
  const typeaheadSearchContainer = document.querySelector('.js-a-search-container');
  const dropdownHeaders = document.querySelectorAll('.js-nav-dropdown__header');
  const myCarDrawer = document.querySelector('.js-my-car-drawer');
  let scrollAnimationID = null;
  let isScrolling = false;
  let isSearchLineHidden = false;
  let showSearchOffset;
  let searchLineHeight;
  let hideSearchOffset;
  let isSearchInitialised = false;
  let activeOverflowDropdown;
  let overlay;

  // Selectors
  const navDrawerCloseSelector = '.js-nav-drawer-close';
  const backBtnSelector = '.js-nav-dropdown__back-btn';
  const mainBackBtnSelector = '.js-nav-dropdown__back-btn--main';
  const openDrawerSelector = '.js-open-drawer-left';
  const myCarNavBtnSelector = '.js-my-car-nav-btn';
  const exclusionSelectors =
    '.nav-drawer, .nav-drawer__inner, .nav-drawer__header, .nav-dropdown, .nav-dropdown__content, .nav-dropdown__list, .primary-nav__item, .primary-nav__item--child';
  const scrollSelector = '.js-nav-scroller';
  const initSearchSelector = '.js-search-init';
  const searchCloseBtn = '.js-typeahead-search__close-btn';

  // Hide the search icon on the search results page
  const { pathname } = window.location;
  const searchIcon = document.querySelector(initSearchSelector);
  const searchBoxOnResultsPage = document.querySelector('.js-typeahead-search-on-results-page');

  if (pathname.match(/^\/search-results\/$/) && searchIcon && searchBoxOnResultsPage) {
    searchIcon.style.display = 'none';
    searchBoxOnResultsPage.style.display = 'block';
  }

  function buildOverlay() {
    if (!overlay) {
      overlay = document.createElement('div');
      overlay.classList.add('primary-nav__overlay', 'js-primary-nav__overlay');
      document.body.appendChild(overlay);
    }
  }

  // Handle primary nav overlay
  function handleOverlay() {
    if (!desktopView.matches) {
      if (document.body.classList.contains('nav-dropdown-open')) {
        document.body.classList.remove('nav-dropdown-open');
      }
      document.body.classList.remove('nav-dropdown-open');
      if (overlay && overlay.classList.contains('is-active')) {
        overlay.classList.remove('is-active');
        const dropdowns = document.querySelectorAll('.js-nav-dropdown-wrapper.is-active');
        dropdowns.forEach((dr) => {
          dr.classList.remove('is-active');
        });
      }
    }
  }

  // Update offsets to hide/show the search line.
  function updateOffsets() {
    if (robertsMessage) {
      showSearchOffset =
        robertsMessage && getComputedStyle(robertsMessage).display !== 'none'
          ? parseInt(getComputedStyle(robertsMessage).height, 10)
          : 0;
    }

    if (typeaheadSearch) {
      searchLineHeight =
        typeaheadSearch && getComputedStyle(typeaheadSearch).display !== 'none'
          ? parseInt(getComputedStyle(typeaheadSearch).height, 10)
          : 0;
    }

    hideSearchOffset =
      showSearchOffset + searchLineHeight + parseInt(getComputedStyle(nav).paddingTop, 10);
  }

  // Toggle the search line.
  function toggleSearchLine() {
    isSearchLineHidden = !isSearchLineHidden;

    // if (typeaheadSearch) {
    //   brandLogoContainer.classList.toggle('hide__default');
    //   typeaheadSearch.classList.toggle('hide__default');
    // }

    if (navContainer && nav) {
      if (isSearchLineHidden) {
        navContainer.style.position = 'sticky';
        nav.classList.add('primary-nav--is-sticky');
      } else if (!isSearchLineHidden) {
        navContainer.style.position = 'relative';
        nav.classList.add('primary-nav--is-sticky');
        window.scroll(0, hideSearchOffset - 1);
      }
    }
  }

  // Handle the hiding/states of the search line.
  function handleSearchLine() {
    if (mobileView.matches) {
      updateOffsets();
      if (!isSearchLineHidden && window.pageYOffset > hideSearchOffset) {
        toggleSearchLine();
      } else if (isSearchLineHidden && window.pageYOffset <= showSearchOffset) {
        toggleSearchLine();
      }
    }
  }

  // Initializes the search line hiding/showing functionalities.
  function initSearchLine() {
    if (!isSearchInitialised) {
      handleSearchLine();
      window.addEventListener('scroll', handleSearchLine, false);
      isSearchInitialised = true;
    }
  }

  // Destorys the search line hiding/showing functionlities.
  function destroySearchLine() {
    if (isSearchInitialised) {
      if (isSearchLineHidden) {
        toggleSearchLine();
      }
      navContainer.style.position = '';
      isSearchInitialised = false;
    }
  }

  // Handle the close of the search
  function handleSearchClose() {
    primaryNav.classList.remove('search-is-focused');
    if (typeaheadSearchContainer) {
      typeaheadSearchContainer.querySelector('.typeahead-search').style.cssText = '';
    }
  }

  // Handle scroll events, remove if not on mobile
  function handleEventListeners() {
    if (mobileView.matches) {
      if (typeaheadSearch) {
        initSearchLine();
      }
      // Focus on input listener
      if (searchInput) {
        searchInput.addEventListener('focus', () => {
          primaryNav.classList.add('search-is-focused');
        });

        // When user leaves the input
        searchInput.addEventListener('blur', () => {
          handleSearchClose();
        });
      }
    } else if (!mobileView.matches) {
      if (typeaheadSearch) {
        destroySearchLine();
      }
    }
  }

  // Window resize function -- handle states
  function handleWindowResize() {
    handleEventListeners();
    handleSearchLine();
    handleOverlay();
  }

  // Add a listener to resize
  let eventEnd;
  window.addEventListener('resize', () => {
    clearTimeout(eventEnd);
    eventEnd = setTimeout(handleWindowResize, 100);
  });

  // Focus on input listener
  if (searchInput) {
    searchInput.addEventListener('focus', () => {
      primaryNav.classList.add('search-is-focused');
    });

    // When user laves the input
    searchInput.addEventListener('blur', () => {
      handleSearchClose();
    });
  }

  function findScroller(direction) {
    if (activeOverflowDropdown) {
      return activeOverflowDropdown.parentNode.querySelector(`${scrollSelector}-${direction}`);
    }
    return null;
  }

  function showScroller(direction) {
    const scrollEl = findScroller(direction);
    if (scrollEl && scrollEl.classList.contains('hide__default')) {
      scrollEl.classList.remove('hide__default');
    }
  }

  function checkShowScroller() {
    if (
      activeOverflowDropdown.scrollHeight >
      activeOverflowDropdown.scrollTop + activeOverflowDropdown.offsetHeight
    ) {
      showScroller('down');
    }
    if (activeOverflowDropdown.scrollTop > 0) {
      showScroller('up');
    }
  }

  function hideScroller(direction) {
    const scrollEl = findScroller(direction);
    if (scrollEl && !scrollEl.classList.contains('hide__default')) {
      scrollEl.classList.add('hide__default');

      if (isScrolling) {
        isScrolling = false;
        clearTimeout(scrollAnimationID);
      }
    }
  }

  function checkScrollerAtEnd() {
    if (activeOverflowDropdown.scrollTop === 0) {
      hideScroller('up');
    }

    if (
      activeOverflowDropdown.scrollHeight ===
      activeOverflowDropdown.scrollTop + activeOverflowDropdown.offsetHeight
    ) {
      hideScroller('down');
    }
  }

  function handleActiveOverflowDropdownScrolled() {
    checkScrollerAtEnd();
    checkShowScroller();
  }

  // When clicked outside excluded elements, removes all active states
  function resetActiveStates() {
    const hasActiveFlyoutDropdowns = document.querySelectorAll(
      '.js-nav-dropdown.has-active-flyout',
    );
    const navDropdowns = document.querySelectorAll('.js-nav-dropdown-wrapper.is-active');
    const flyoutDropdowns = document.querySelectorAll('.js-nav-dropdown-wrapper-flyout.is-active');

    // Remove activeOverflowDropdown listener and reset the variable.
    if (activeOverflowDropdown) {
      activeOverflowDropdown.removeEventListener(
        'scroll',
        handleActiveOverflowDropdownScrolled,
        false,
      );
    }

    openDrawer.classList.remove('is-active');
    document.body.classList.remove('nav-drawer-open');
    document.body.classList.remove('nav-dropdown-open');
    document.documentElement.classList.remove('nav-drawer-open');

    navDropdowns.forEach((dr) => {
      dr.classList.remove('is-active');
      if (overlay && desktopView.matches) {
        overlay.classList.remove('is-active');
      }
    });
    flyoutDropdowns.forEach((dr) => {
      dr.classList.remove('is-active');
    });

    hasActiveFlyoutDropdowns.forEach((dr) => {
      dr.classList.remove('has-active-flyout');
    });
  }

  // for scroll on hover animation...
  function scrollOnHover(direction, old) {
    const now = Date.now();

    if (direction === 'down') {
      activeOverflowDropdown.scrollTop += (5 * (now - old)) / 10;
      showScroller('up');
    } else if (direction === 'up') {
      activeOverflowDropdown.scrollTop -= (5 * (now - old)) / 10;
      showScroller('down');
    }

    checkScrollerAtEnd();

    if (isScrolling) {
      scrollAnimationID = setTimeout(scrollOnHover, 10, direction, now);
    }
  }

  function addScroller(direction) {
    if (!findScroller(direction)) {
      // Create a div element to be target for scrolling list items...
      const scrollEl = document.createElement('div');

      scrollEl.classList.add(`js-nav-scroller-${direction}`, `scroller--${direction}`);
      const styles = {
        display: 'block',
        position: 'absolute',
        zIndex: 2,
        left: 0,
        right: 0,
        height: '2em',
        width: '100%',
        borderBottomRightRadius: '20px',
        borderBottomLeftRadius: '20px',
        cursor: 'pointer',
      };

      if (direction === 'up') {
        Object.assign(styles, { top: '0' });
      } else if (direction === 'down') {
        Object.assign(styles, { bottom: '0' });
      }

      Object.assign(scrollEl.style, styles);
      // mouseover listener for scroll element to start scrolling...
      scrollEl.addEventListener('mouseover', (event) => {
        event.preventDefault();
        isScrolling = true;

        scrollAnimationID = setTimeout(scrollOnHover, 10, direction, Date.now());
      });

      // mouseleave listener for scroll element to stop scrolling..
      scrollEl.addEventListener('mouseleave', () => {
        isScrolling = false;
        clearTimeout(scrollAnimationID);
      });

      scrollEl.addEventListener('click', (event) => {
        event.stopPropagation();
        event.preventDefault();
      });

      activeOverflowDropdown.parentNode.appendChild(scrollEl);
    }
  }

  function setupScrollOnHover() {
    const navScroller = document.querySelectorAll(scrollSelector);
    if (navScroller) {
      navScroller.forEach((el) => {
        document.remove(el);
      });
    }
    addScroller('up');
    addScroller('down');
    checkScrollerAtEnd();
  }

  // Handle dropdown overflow
  function handleOverflowDropdown() {
    if (desktopView.matches) {
      const activeDropdown = document.querySelector(
        '.js-nav-dropdown-wrapper.is-active .nav-dropdown__content',
      );
      const oneLevelFlyout = document.querySelector(
        '.js-nav-dropdown.js-nav-dropdown--with-overflow-fade-one-level',
      );

      if (activeDropdown) {
        const activeDropdownRect = activeDropdown.getBoundingClientRect();

        if (oneLevelFlyout) {
          activeOverflowDropdown = document.querySelector(
            '.nav-dropdown-wrapper.is-active .js-nav-dropdown--with-overflow-fade-one-level > .nav-dropdown__content',
          );
        }

        // Handle activeOverflowDropdown initialisation.
        if (activeOverflowDropdown) {
          activeOverflowDropdown.style.maxHeight = `${
            activeDropdownRect.height >= 380 ? activeDropdownRect.height : 380
          }px`;
          activeOverflowDropdown.addEventListener(
            'scroll',
            handleActiveOverflowDropdownScrolled,
            false,
          );
          setupScrollOnHover();
        }
      }
    }
  }

  // Handle nav dropdowns
  function handleDropdownFlyout(event) {
    const clickedEl = event.target;
    const dropdowns = document.querySelectorAll('.js-nav-dropdown.has-active-flyout');
    const dropdown = clickedEl.closest('.js-nav-dropdown');
    const flyoutDropdown = clickedEl.closest('.js-nav-dropdown-wrapper-flyout');
    const flyoutDropdowns = document.querySelectorAll('.js-nav-dropdown-wrapper-flyout.is-active');
    const dropdownHeader = clickedEl.closest('.js-nav-dropdown__header');

    // Remove activeOverflowdropdown and its listener.
    if (activeOverflowDropdown) {
      activeOverflowDropdown.removeEventListener(
        'scroll',
        handleActiveOverflowDropdownScrolled,
        false,
      );
      activeOverflowDropdown = false;
    }

    dropdowns.forEach((dr) => {
      if (dr !== dropdown) {
        dr.classList.remove('has-active-flyout');
      }
    });
    flyoutDropdowns.forEach((dr) => {
      if (dr !== flyoutDropdown) {
        dr.classList.remove('is-active');
      }
    });

    dropdownHeaders.forEach((header) => {
      if (header !== dropdownHeader) {
        header.classList.toggle('hide__default');
      }
    });
    dropdown.classList.toggle('has-active-flyout');
    flyoutDropdown.classList.toggle('is-active');
    activeOverflowDropdown = document.querySelector(
      '.js-nav-dropdown-wrapper-flyout.is-active .js-nav-dropdown--with-overflow-fade > .nav-dropdown__content',
    );
  }

  // Handle nav dropdown
  function handleDropdown(event) {
    const clickedEl = event.target;
    const dropdowns = document.querySelectorAll('.js-nav-dropdown-wrapper.is-active');
    const dropdown = clickedEl.closest('.js-nav-dropdown-wrapper');
    const flyoutDropdown = clickedEl.closest('.js-nav-dropdown-wrapper-flyout');
    const flyoutDropdowns = document.querySelectorAll('.js-nav-dropdown-wrapper-flyout.is-active');

    dropdowns.forEach((dr) => {
      if (dr !== dropdown) {
        dr.classList.remove('is-active');
        document.body.classList.remove('nav-dropdown-open');
        if (overlay && desktopView.matches) {
          overlay.classList.remove('is-active');
          document.body.classList.remove('nav-dropdown-open');
        }
      }
    });
    flyoutDropdowns.forEach((dr) => {
      if (dr !== flyoutDropdown) {
        dr.classList.remove('is-active');
      }
    });
    dropdown.classList.toggle('is-active');
    if (overlay && desktopView.matches) {
      overlay.classList.toggle('is-active');
      document.body.classList.toggle('nav-dropdown-open');
    }
  }

  // Handle the click of the search icon
  function handleSearchInit() {
    primaryNav.classList.add('search-is-focused');
    if (typeaheadSearchContainer) {
      typeaheadSearchContainer.querySelector('.typeahead-search').style.cssText = 'display: block';
    }
  }

  // Nav dropdown back button
  function handleBackBtnEvents(event) {
    const clickedEl = event.target;
    const activeFlyoutDropdown = clickedEl.closest('.js-nav-dropdown.has-active-flyout');
    const activeFlyout = clickedEl.closest('.js-nav-dropdown-wrapper-flyout.is-active');
    const dropdownHeader = clickedEl.closest('.js-nav-dropdown__header');
    activeFlyoutDropdown.classList.remove('has-active-flyout');
    activeFlyout.classList.remove('is-active');

    dropdownHeaders.forEach((header) => {
      if (header !== dropdownHeader) {
        header.classList.toggle('hide__default');
      }
    });
  }

  // Main back button event
  function handleMainBckBtn(event) {
    const clickedEl = event.target;
    const activeDropdown = clickedEl.closest('.js-nav-dropdown-wrapper.is-active');
    activeDropdown.classList.remove('is-active');
  }

  // Handle the nav drawer
  function handleOpenDrawer() {
    openDrawer.classList.toggle('is-active');
    document.body.classList.toggle('nav-drawer-open');
    document.documentElement.classList.toggle('nav-drawer-open');
    buildOverlay();
  }

  // Handle the closing of the nav drawer
  function handleCloseDrawer() {
    openDrawer.classList.remove('is-active');
    document.body.classList.remove('nav-drawer-open');
    document.documentElement.classList.remove('nav-drawer-open');
    if (overlay) {
      overlay.classList.remove('is-active');
    }
  }

  /**
   * Handle the my car drawer opening. Copy all items from the js-my-car-list
   * the first time to avoid maintenance for two sections in the html
   * @param {MouseEvent} event The mouse event that occured
   */
  function handleMyCarDrawer(event) {
    myCarDrawer.style.display = '';
    const myCarItems = document.querySelector('.js-my-car-drawer .js-primary-nav__items');

    if (myCarItems.textContent.trim() === '') {
      const myListItems = document.querySelectorAll('.js-my-car-list');
      let results = '';
      for (let i = 0, len = myListItems.length; i < len; i += 1) {
        results += myListItems[i].innerHTML;
      }
      myCarItems.innerHTML = results;
    }
    handleOpenDrawer(event);
  }

  /**
   * Display my car item if the custom onLogIn event occurs or if the user is already logged in
   * @param {CustomEvent} event The custom event that occured
   */
  function displayMyCarItem(event) {
    let loggedIn = false;

    if (event instanceof CustomEvent) {
      loggedIn = true;
    }

    // Check if the user is logged in, then hide the login button and display the my car button menu
    const userStatusInput = document.querySelector('#userStatus');

    if ((userStatusInput && userStatusInput.value !== 'loggedOut') || loggedIn) {
      const myCarLogin = document.querySelector('.js-my-car-login');
      const loginElement = document.querySelector('.js-login-element');
      const logoutElements = document.querySelectorAll('.js-logout-element');

      /**
       * If site has My Car
       */
      if (myCarLogin) {
        myCarLogin.classList.add('hide__default');
        document.querySelector('.js-open-my-car').classList.remove('hide__default');
      }

      /**
       * If on Vertu Careers
       */
      if (loginElement) {
        loginElement.classList.add('hide__default');
        logoutElements.forEach((el) => {
          el.classList.remove('hide__default');
        });
      }
      window.removeEventListener('onLogIn', displayMyCarItem);
    }

    // if ((userStatusInput && userStatusInput.value !== 'loggedOut') || loggedIn) {
    //   document.querySelector('.js-my-car-login').classList.add('hide__default');
    //   document.querySelector('.js-open-my-car').classList.remove('hide__default');
    //   window.removeEventListener('onLogIn', displayMyCarItem);
    // }
  }

  // Add an event listener to the Nav container
  document.addEventListener(
    'click',
    (event) => {
      // Get the event target
      const clickedEl = event.target;

      // Deligate MyCar navigation clicks to the MyCarFeatures and resetActiveStates.
      if (clickedEl.matches(myCarNavBtnSelector)) {
        window.MyCarFeatures.handleNavigationClick(event);
        resetActiveStates();
      }

      if (clickedEl.hasAttribute('href')) return;

      // If the search init icon is clicked
      if (clickedEl.matches(initSearchSelector)) {
        handleSearchInit();
      } else if (clickedEl.matches(searchCloseBtn)) {
        handleSearchClose();
      } else if (clickedEl.matches(openDrawerSelector)) {
        // Hide the my-car drawer when the menu drawer is opened
        if (myCarDrawer) {
          myCarDrawer.style.display = 'none';
        }
        handleOpenDrawer(event);
      } else if (clickedEl.matches('.js-open-my-car') && !desktopView.matches) {
        handleMyCarDrawer(event);
      } else if (clickedEl.matches(navDrawerCloseSelector)) {
        // Remove display none from the my-car drawer in order for the slide-in animation to work
        if (myCarDrawer) {
          myCarDrawer.style.display = '';
        }
        handleCloseDrawer(event);
      } else if (clickedEl.matches('.primary-nav__item--child')) {
        // MyCar nav buttons are handled by MyCarFeatures => only handle the active states here
        handleDropdownFlyout(event);
        handleOverflowDropdown(event);
      } else if (clickedEl.matches('.primary-nav__item')) {
        buildOverlay();
        handleDropdown(event);
        handleOverflowDropdown(event);
      } else if (clickedEl.matches(backBtnSelector)) {
        handleBackBtnEvents(event);
      } else if (clickedEl.matches(mainBackBtnSelector)) {
        handleMainBckBtn(event);
      } else if (!clickedEl.matches(exclusionSelectors)) {
        resetActiveStates();
      }
    },
    false,
  );

  window.vm.onload(() => {
    displayMyCarItem();
    handleWindowResize();
  });
  window.addEventListener('onLogIn', displayMyCarItem);
})(window, document);
